<template>
  <div class="create add-job pb-2">
    <CCardGroup class="has-loading-overlay">
      <CCard class="benefit">
        <CCardBody class="p-3">
          <h4 class="text-primary mb-3">Benefits</h4>
          <div style="width: 100%; overflow: auto">
            <table
              class="table table-striped table-hover border-bottom mb-0 desktop-view"
              v-if="stdBenefits.length || addBenefits.length"
            >
              <thead>
                <tr style="background-color: lightgrey">
                  <td style="width: 3%">
                    <label>
                      <input
                        type="checkbox"
                        v-model="selectAll"
                        @click="select"
                      />
                    </label>
                  </td>
                  <td>Benefit Type</td>
                  <td>Description</td>
                  <td>Published Comments</td>
                  <td>Private Comments</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    v-if="stdBenefits.length"
                    colspan="7"
                    class="text-primary"
                    style="font-weight: 600; background-color: whitesmoke"
                  >
                    Standard Benefits
                  </td>
                </tr>
                <tr
                  v-for="i in stdBenefits"
                  :key="i.organisation_job_benefit_id"
                  :style="`${
                    selected.includes(i.organisation_job_benefit_id)
                      ? 'background-color: #D0F0C0'
                      : 'background-color: white'
                  }`"
                >
                  <td>
                    <label class="form-checkbox">
                      <input
                        type="checkbox"
                        :value="i.organisation_job_benefit_id"
                        v-model="selected"
                        @change="checkSelectAll"
                      />
                    </label>
                  </td>
                  <td>{{ i.benefitType }}</td>
                  <td>{{ i.description }}</td>
                  <td>{{ i.comments || "--" }}</td>
                  <td>{{ i.comments_internal || "--" }}</td>
                  <td>
                    <em @click="editBenefit(i)">
                      <CIcon
                        name="cil-pencil"
                        style="height: 18px"
                        :style="`${
                          selected.includes(i.organisation_job_benefit_id)
                            ? 'cursor:pointer'
                            : 'cursor: no-drop;'
                        }`"
                      />
                    </em>
                  </td>
                </tr>
                <tr>
                  <td
                    v-if="addBenefits.length"
                    colspan="7"
                    class="text-primary"
                    style="font-weight: 600; background-color: whitesmoke"
                  >
                    Additional Benefits
                  </td>
                </tr>
                <tr
                  v-for="i in addBenefits"
                  :key="i.organisation_job_benefit_id"
                  :style="`${
                    selected.includes(i.organisation_job_benefit_id)
                      ? 'background-color: #D0F0C0'
                      : 'background-color: white'
                  }`"
                >
                  <td>
                    <label class="form-checkbox">
                      <input
                        type="checkbox"
                        :value="i.organisation_job_benefit_id"
                        v-model="selected"
                        @change="checkSelectAll"
                      />
                    </label>
                  </td>
                  <td>{{ i.benefitType }}</td>
                  <td>{{ i.description }}</td>
                  <td>{{ i.comments || "--" }}</td>
                  <td>{{ i.comments_internal || "--" }}</td>
                  <td>
                    <i @click="editBenefit(i)">
                      <CIcon
                        name="cil-pencil"
                        style="height: 18px"
                        :style="`${
                          selected.includes(i.organisation_job_benefit_id)
                            ? 'cursor:pointer'
                            : 'cursor: no-drop;'
                        }`"
                      />
                    </i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="mobile-view benefit-mobile"
            v-if="stdBenefits.length || addBenefits.length"
          >
            <CRow
              v-if="stdBenefits.length"
              class="text-primary pt-1"
              style="font-weight: 600; background-color: whitesmoke"
            >
              <label class="ml-4">
                <input type="checkbox" v-model="selectAll" @click="select" />
              </label>
              <span class="ml-3"> Standard Benefits </span>
            </CRow>
            <div
              v-for="i in stdBenefits"
              :key="i.organisation_job_benefit_id"
              style="background-color: whitesmoke"
              class="mt-1 border-bottom"
              :style="`${
                selected.includes(i.organisation_job_benefit_id)
                  ? 'background-color: #D0F0C0'
                  : 'background-color: whitesmoke'
              }`"
            >
              <div class="widget-content-actions text-right">
                <CButton
                  class="icon icon-edit mr-2 mt-1 small"
                  @click="editBenefit(i)"
                >
                </CButton>
              </div>
              <CRow class="ml-2">
                <div class="ben-table col col-1">
                  <p class="head mt-3">
                    <input
                      type="checkbox"
                      :value="i.organisation_job_benefit_id"
                      v-model="selected"
                      @change="checkSelectAll"
                    />
                  </p>
                </div>
                <div class="ben-table col col-5">
                  <p class="head">Benefit Type</p>
                  <p class="data">{{ i.benefitType }}</p>
                </div>
                <div class="ben-table col col-6 pr-3">
                  <p class="head">Description</p>
                  <p class="data">{{ i.description }}</p>
                </div>
                <div class="ben-table col col-1"></div>
                <div class="ben-table col col-5">
                  <p class="head">Published Comments</p>
                  <p class="data">{{ i.comments || "--" }}</p>
                </div>
                <div class="ben-table col col-6">
                  <p class="head">Private Comments</p>
                  <p class="data">{{ i.comments_internal || "--" }}</p>
                </div>
              </CRow>
            </div>
            <CRow
              v-if="addBenefits.length"
              class="text-primary pt-1 pl-4 mt-3"
              style="font-weight: 600; background-color: whitesmoke"
            >
              Additional Benefits
            </CRow>
            <div
              v-for="i in addBenefits"
              :key="i.organisation_job_benefit_id"
              style="background-color: whitesmoke"
              class="mt-1 border-bottom"
              :style="`${
                selected.includes(i.organisation_job_benefit_id)
                  ? 'background-color: #D0F0C0'
                  : 'background-color: whitesmoke'
              }`"
            >
              <div class="widget-content-actions text-right">
                <CButton
                  class="icon icon-edit mr-2 mt-1 small"
                  @click="editBenefit(i)"
                >
                </CButton>
              </div>
              <CRow class="ml-2">
                <div class="ben-table col col-1">
                  <p class="head mt-3">
                    <input
                      type="checkbox"
                      :value="i.organisation_job_benefit_id"
                      v-model="selected"
                      @change="checkSelectAll"
                    />
                  </p>
                </div>
                <div class="ben-table col col-5">
                  <p class="head">Benefit Type</p>
                  <p class="data">{{ i.benefitType }}</p>
                </div>
                <div class="ben-table col col-6 pr-3">
                  <p class="head">Description</p>
                  <p class="data">{{ i.description }}</p>
                </div>
                <div class="ben-table col col-1"></div>
                <div class="ben-table col col-5">
                  <p class="head">Comments</p>
                  <p class="data">{{ i.comments || "--" }}</p>
                </div>
                <div class="ben-table col col-6 pr-3">
                  <p class="head">Private Comments</p>
                  <p class="data">{{ i.comments_internal || "--" }}</p>
                </div>
              </CRow>
            </div>
          </div>
          <div
            v-if="!(stdBenefits.length || addBenefits.length)"
            class="text-center"
          >
            <h5>No Benefits Found</h5>
          </div>
        </CCardBody>
      </CCard>
    </CCardGroup>
    <div
      class="row-sm my-2 action-group d-flex justify-content-between flex-wrap"
    >
      <div class="btn-add-job-cancel">
        <button
          name="cancel"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg"
          @click="$parent.goToJobBoard()"
        >
          Cancel
        </button>
      </div>
      <div class="btn-add-job-submit">
        <button
          name="Back"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
          @click="onSubmit('back', 1)"
          :disabled="isLoading"
        >
          Back
        </button>

        <button
          name="draft"
          type="submit"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3 mr-2"
          @click="onSubmit('draft')"
          :disabled="isLoading"
        >
          Save as Draft
          <CSpinner
            v-if="isLoading == 'draft'"
            class="spinner-border-sm text-white m-1"
          />
        </button>

        <button
          name="next_page"
          type="submit"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3"
          @click="onSubmit('next', 3)"
          :disabled="isLoading"
        >
          {{ isEditJob ? "Save & Continue" : "Next" }}
          <CSpinner
            v-if="isLoading == 'next'"
            class="spinner-border-sm text-white m-1"
          />
        </button>
      </div>
    </div>
    <BenefitEdit :isShowPopup="isShowPopup" :benefitData="editBenefitData" />
  </div>
</template>

<script>
import BenefitEdit from "./JobBenefitsEditModal";
import AddJob from "./AddJob";
import { deepClone } from "@/helpers/helper";
import { mapActions } from "vuex";
export default {
  name: "JobBenefits",
  mixins: [AddJob],
  components: {
    BenefitEdit,
  },
  data: () => ({
    mainBenefits: [],
    tempBenefits: [],
    showBenefits: [],
    selected: [],
    selectAll: false,
    isShowPopup: false,
    editBenefitData: {},
    isLoading: false,
    isEditJob: false,
  }),
  computed: {
    stdBenefits() {
      if (Object.keys(this.showBenefits).length) {
        return this.showBenefits
          .filter((val) => val.benefit_types == "standard_benefits")
          .sort((a, b) => a.display_order - b.display_order);
      }
      return [];
    },
    addBenefits() {
      if (Object.keys(this.showBenefits).length) {
        return this.showBenefits
          .filter((val) => val.benefit_types == "additional_benefits")
          .sort((a, b) => a.display_order - b.display_order);
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["createBenefit", "updateBenefit"]),
    editBenefit(data) {
      if (this.selected.includes(data.organisation_job_benefit_id)) {
        this.editBenefitData = deepClone(data);
        this.isShowPopup = true;
      }
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.showBenefits) {
          this.selected.push(this.showBenefits[i].organisation_job_benefit_id);
        }
      }
    },
    checkSelectAll() {
      let temp = false;
      this.showBenefits?.forEach((val) => {
        this.selected.includes(val.organisation_job_benefit_id)
          ? null
          : (temp = true);
      });
      temp ? (this.selectAll = false) : (this.selectAll = true);
    },
    onSubmit(selectedBtn, step) {
      this.isLoading = selectedBtn;
      const { customer_uid, job_id } = this.jobById;
      let appendAction = [];
      if (Object.keys(this.jobById?.benefits).length) {
        this.jobById?.benefits.forEach((val) => {
          if (!this.selected.includes(val.organisation_job_benefit_id))
            appendAction = this.deleteBenefitWithoutToast({
              customer_uid: customer_uid,
              job_id: job_id,
              job_benefit_id: val.job_benefit_id,
            });
        });
      }
      if (this.selected?.length) {
        this.selected.forEach((selectedID) => {
          let bnft = this.tempBenefits[`benefit_${selectedID}`];
          if (!bnft?.job_benefit_id) {
            appendAction = this.createBenefit({
              organisation_job_benefit_id: bnft?.organisation_job_benefit_id,
              comments: bnft.comments,
              comments_internal: bnft.comments_internal,
              description: bnft.description,
              customer_uid,
              job_id,
            });
          } else {
            let extBnft = this.mainBenefits[`benefit_${selectedID}`];
            if (!_.isEqual(extBnft, bnft)) {
              appendAction = this.updateBenefit({
                job_benefit_id: bnft.job_benefit_id,
                organisation_job_benefit_id: bnft?.organisation_job_benefit_id,
                comments: bnft.comments,
                comments_internal: bnft.comments_internal,
                description: bnft.description,
                job_id,
                customer_uid,
              });
            }
          }
        });
      }
      Promise.all([appendAction]).then((res) => {
        if (appendAction.length != 0) {
          this.fetchJobByID(job_id).then(() => {
            this.showToast({
              class: "bg-success text-white",
              message: "Benefits Updated Successfully!",
            });
            this.isLoading = false;
            if (selectedBtn != "draft") this.$emit("step", step);
            this.initialSetup();
          });
        } else {
          this.isLoading = false;
          if (selectedBtn != "draft") this.$emit("step", step);
        }
      });
    },
    initialSetup() {
      this.selected = [];
      if (Object.keys(this.getOrgJobBenefits).length) {
        let benefits = this.getOrgJobBenefits.map((val) => ({
          benefitType: val.benefit_type.benefit_type,
          description: val.description,
          comments: val.comments,
          comments_internal: null,
          organisation_job_benefit_id: val.organisation_job_benefit_id,
          benefit_types: val.benefit_types,
          display_order: val.display_order,
        }));
        benefits.forEach((val) => {
          this.tempBenefits[`benefit_${val.organisation_job_benefit_id}`] = val;
        });
      }
      if (Object.keys(this.jobById?.benefits).length) {
        let benefits = this.jobById?.benefits?.map((val) => ({
          benefitType: val.organisation_job_benefit.benefit_type.benefit_type,
          description: val.description,
          comments: val.comments,
          comments_internal: val.comments_internal,
          job_benefit_id: val.job_benefit_id,
          organisation_job_benefit_id: val.organisation_job_benefit_id,
          benefit_types: val.organisation_job_benefit.benefit_types,
          display_order: val.organisation_job_benefit?.display_order,
        }));
        benefits.forEach((val) => {
          this.tempBenefits[`benefit_${val.organisation_job_benefit_id}`] = val;
          this.selected.push(val.organisation_job_benefit_id);
        });
      }
      this.showBenefits = [];
      for (const [key, value] of Object.entries(this.tempBenefits)) {
        this.showBenefits.push(value);
      }
      this.mainBenefits = deepClone(this.tempBenefits);
      this.checkSelectAll();
      this.hideLoader();
    },
    emitBenefits() {
      let showMicroPreview = [];
      this.showBenefits?.forEach((v) => {
        if (this.selected.includes(v.organisation_job_benefit_id)) {
          showMicroPreview.push(v);
        }
      });
      this.$emit("tempBenefits", showMicroPreview);
    },
  },
  mounted() {
    const {
      organisation_uid,
      candidate_type: { candidate_type_id },
      customer_uid,
    } = this.jobById;
    this.showLoader();
    this.fetchOrgJobBenefits({
      organisation_uid,
      candidate_type_id,
      customer_uid,
      benefit_types: "standard_benefits,additional_benefits",
    }).then(() => {
      this.initialSetup();
    });
    if (this.$router.currentRoute.name == "EditJob") this.isEditJob = true;
  },
  watch: {
    selected() {
      this.emitBenefits();
    },
    showBenefits() {
      this.emitBenefits();
    },
  },
};
</script>

<style lang="scss" scoped>
.table thead tr td {
  font-weight: 700;
}
</style>
