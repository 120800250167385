<template>
  <div class="add-job pb-2">
    <LoadingOverlay v-if="!isFetchingJobData" />
    <ValidationObserver ref="addJobInfo" v-slot="{ handleSubmit }">
      <form id="create" @submit.prevent="handleSubmit()">
        <CCardGroup class="has-loading-overlay">
          <!-- Card Starts Here -->
          <CCard>
            <!-- Main Section Starts-->
            <CCardBody class="p-3">
              <!-- Job Information Section Starts Here -->
              <h5 class="text-primary">Job Information</h5>
              <CRow class="mt-4">
                <CCol md="6" class="mb-3">
                  <!-- Comment #1 List Partner Hospital For UK Main Agency Else List Organisations-->
                  <CRow v-if="isUKMainAgency" class="row">
                    <div class="row col-12 pr-0">
                      <label class="required col-10"> Organisation/Client </label>
                      <span
                        class="pl-3 mb-1"
                        style="font-size: 10px; font-weight: 600"
                      >
                      Select the name you are recruiting for. Update Branding/logo in Screen 5.
                      </span>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="organisation"
                          :value="jobs.organisation"
                          @input="handleChangeSelect"
                          :options="
                            options && options['agencyAndPartnerHospitals']
                              ? options['agencyAndPartnerHospitals']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                          :disabled="isJobCreated"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow v-else class="row">
                    <label class="required col-lg-12 col-md-12">
                      Organisation/Client
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="organisation"
                          :value="jobs.organisation"
                          @input="handleChangeSelect"
                          :options="
                            options && options['organisation']
                              ? options['organisation']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                          :disabled="isJobCreated"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <!-- Comment #1 Ends -->

                <!-- Job Number Input Field -->
                <!-- <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Job Number</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextInput
                        name="job_id"
                        :value="jobs.display_uid"
                        :disabled="true"
                      />
                    </div>
                  </CRow>
                </CCol> -->
                
                <CCol md="6" class="mb-3 mt-2">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12">
                      Job Title
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="job_title"
                          :value="jobs.job_title"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 required">
                      Job Location
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="location_id"
                          :value="jobs.location_id"
                          @input="handleChangeSelect"
                          :options="
                            options && options['location_id']
                              ? options['location_id']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Display Location
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider v-slot="{ errors }">
                        <TextInput
                          name="display_location"
                          :value="jobs.display_location"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12 d-flex">
                      Candidate Type
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="candidateType"
                          :value="jobs.candidateType"
                          @input="handleChangeSelect"
                          :options="
                            options && options['candidateType']
                              ? options['candidateType']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                          :disabled="isJobCreated"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <!-- Comment #2 Candidate Level is not shown for AHP -->
                <!-- Level Id is multi-selectable except Physician  -->
                <CCol md="6" class="mb-3" v-if="!isAHP">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12 d-flex">
                      Level
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col infoicon-multi-select">
                      <ValidationProvider
                        ref="level"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <Select
                          name="level"
                          :value="jobs.level"
                          @input="handleChangeSelect"
                          :options="
                            options && options['level_id']
                              ? options['level_id']
                              : []
                          "
                          :taggable="false"
                          :multiple="true"
                          :clearable="true"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
                <!-- Comment #2 Ends -->
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12 d-flex">
                      {{
                        isAHP
                          ? "Requirement"
                          : isNonClinicAdmin
                          ? "Main Area of Expertise"
                          : "Speciality"
                      }}
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="speciality"
                          :value="jobs.speciality"
                          @input="handleChangeSelect"
                          :options="
                            options && isNurse
                              ? options['nurseSubSpeciality']
                              : options['speciality']
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          noOptionsWhileSearch="No matching options, try similar keywords or email to support@talentfindsolutions.com"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3" v-if="isNonClinicAdmin">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Other areas of expertise
                      <small class="text-primary ml-1 font-weight-bold">
                        (select max 3)
                      </small>
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="other_areas_of_expertise_ids"
                        :value="jobs.other_areas_of_expertise_ids"
                        @input="handleChangeSelect"
                        :options="options ? options['speciality'] : []"
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :limit="3"
                        noOptionsWhileSearch="No matching options, try similar keywords or email to support@talentfindsolutions.com"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select Maximum of 3'"
                      />
                    </div>
                  </CRow>
                </CCol>
                <!-- Comment #3 Show Sub Speciality only if available -->
                <CCol
                  md="6"
                  class="mb-3"
                  v-if="
                    options['subSpeciality'] &&
                    options['subSpeciality'].length &&
                    !isNurse &&
                    !isNonClinicAdmin
                  "
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      {{ isAHP ? "Sub Type / Licensed As" : "Sub Speciality" }}
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider>
                        <Select
                          name="sub_speciality"
                          :value="jobs.sub_speciality"
                          @input="handleChangeSelect"
                          :options="
                            options && options['subSpeciality']
                              ? options['subSpeciality']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="true"
                          noOptionsWhileSearch="No matching options, try similar keywords or email to support@talentfindsolutions.com"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <!-- Comment #3 Ends-->
              </CRow>

              <CRow>
                <!-- Comment #4 Shown only for Nurse and AHP Candidates -->
                <CCol md="6" class="mb-3" v-if="isNurse || isAHP">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Number of years in Speciality({{
                        jobs.experience_in_years || 0
                      }}
                      yrs)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <v-slider
                        name="experience_in_years"
                        :min="0"
                        :max="20"
                        :value="jobs.experience_in_years || 0"
                        @change="handleSliderMinExpSpeciality"
                      ></v-slider>
                    </div>
                  </CRow>
                </CCol>
                <!-- Comment #4 Ends -->
                <!-- Comment #5 Shown Only for Nurse-->
                <CCol md="6" class="mb-3" v-if="isNurse">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Other Areas of Expertise
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="other_areas_of_expertise_ids"
                        :value="jobs.other_areas_of_expertise_ids"
                        @input="handleChangeSelect"
                        :options="
                          options && options['nurseOtherAreaExp']
                            ? options['nurseOtherAreaExp']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="false"
                        :limit="3"
                        noOptionsWhileSearch="No matching options, try similar keywords or email to support@talentfindsolutions.com"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select Maximum of 3'"
                      />
                    </div>
                  </CRow>
                </CCol>
                <!-- Comment #5 Ends -->
                <CCol md="6" class="mb-3" v-if="!(isAHP || isNonClinicAdmin)">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Special Interest(s)
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="special_interest_ids"
                        :value="jobs.special_interest_ids"
                        @input="handleChangeSelect"
                        :options="
                          options && options['special_interest']
                            ? options['special_interest']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                        noOptionsWhileSearch="No matching options, try similar keywords or email to support@talentfindsolutions.com"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <!-- Comment #7 NHS fields shown only for Nurse and for country UK -->
              <CRow
                v-if="
                  isNurse &&
                  jobs.location_id &&
                  jobs.location_id.country_id == 182
                "
              >
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Current NHS Band
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <Select
                        name="current_nhs_band_ids"
                        :value="jobs.current_nhs_band_ids"
                        @input="handleMultiSelectArrayForCountries"
                        :options="
                          options && options['current_nhs_bands']
                            ? options['current_nhs_bands']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <!-- Comment #7 Ends -->
              <!-- Job Information Section Ends -->
              <hr />
              <!-- Contact Section Starts -->
              <h5 class="text-primary">Contact</h5>
              <CRow class="mt-3">
                <CCol v-if="!isJobSupplier" md="6" class="mb-3">
                  <!-- Comment #8 List job_contact_recruiters_for_UK_main For UK Main Agency Else List job_contact_recruiters-->
                  <CRow v-if="isUKMainAgency" class="row">
                    <label class="col-lg-12 col-md-12 required">
                      Assigned Recruiter
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="job_contact_recruiters"
                          :value="jobs.job_contact_recruiters"
                          @input="handleChangeSelect"
                          :options="
                            options &&
                            options['job_contact_recruiters_for_UK_main']
                              ? options['job_contact_recruiters_for_UK_main']
                              : []
                          "
                          :taggable="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow v-else class="row">
                    <label class="col-lg-12 col-md-12 required">
                      Assigned Recruiter
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="job_contact_recruiters"
                          :value="jobs.job_contact_recruiters"
                          @input="handleChangeSelect"
                          :options="
                            options && options['job_contact_recruiters']
                              ? options['job_contact_recruiters']
                              : []
                          "
                          :taggable="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <!-- Comment #8 ends -->
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Point of Contact (Requisitioner)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <Select
                        name="customer_organisation_contact_id"
                        :value="jobs.customer_organisation_contact_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['customer_organisation_contact']
                            ? options['customer_organisation_contact']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="true"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      CV/Submissions Reviewer
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="candidate_reviewer"
                        :value="jobs.candidate_reviewer"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidate_reviewer']
                            ? options['candidate_reviewer']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  md="6"
                  class="mb-3"
                  v-if="!isJobSupplier && job_approvers.length"
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 required">
                      Job Approver
                    </label>
                    <div class="col infoicon-multi-select">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="job_approval"
                          :value="jobs.job_approval"
                          @input="handleChangeSelect"
                          :options="
                            options && options['job_approval']
                              ? options['job_approval']
                              : []
                          "
                          :multiple="true"
                          :error="errors[0]"
                          :clearable="true"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="{
                          placement: 'top',
                          content:'Select one or more. Add Recruiters name if  Senior Approval not necessary',
                          appendToBody: true,
                        }"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Interview Panel
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="candidate_interviewer"
                        :value="jobs.candidate_interviewer"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidate_interviewer']
                            ? options['candidate_interviewer']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <!-- Contact Section Ends -->
              <hr />
              <!-- Optional Requirement Section starts Here -->
              <h5 class="text-primary">Optional Requirements</h5>
              <CRow class="mt-4">
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Qualification Region
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="qualificationRegion"
                        :value="jobs.qualificationRegion"
                        @input="handleMultiSelectArrayForCountries"
                        :options="
                          options && options['qualification_region']
                            ? options['qualification_region']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Qualification Country
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="qualificationCountry"
                        :value="jobs.qualificationCountry"
                        @input="handleMultiSelectArrayForCountries"
                        :options="
                          options && options['qualification_country']
                            ? options['qualification_country']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <!--Comment #9 Min Qual is shown only for Candidate Type - Physician and NonClinicAdmin -->
                <!-- comment #10 Show consider for matching checkbox only when min_qualification_level_id is selected -->
                <CCol
                  md="6"
                  class="mb-2"
                  v-if="isPhysician || isNonClinicAdmin"
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Minimum Qualification Level Required
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="min_qualification_level_id"
                        :value="jobs.min_qualification_level_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['min_qualification_level']
                            ? options['min_qualification_level']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="true"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select my-1"
                        v-c-tooltip="{
                          placement: 'top',
                          content:
                            'Alert! This affects matching. Only select if a higher qualification is necessary.',
                          appendToBody: true,
                        }"
                      />
                    </div>
                  </CRow>
                </CCol>
                <!-- comment #9 Shown only for Candidate Type - Physician Ends-->
                <!-- Comment #10 Ends -->
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 d-flex">
                      Minimum Years of Experience({{ jobs.min_yrs_of_exp || 0 }}
                      yrs)
                      <div class="position-relative superscript-info">
                        <em
                          class="fas fa-circle-info superscript-info-icon"
                          v-c-tooltip="'Considered for Matching'"
                        />
                      </div>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <v-slider
                        name="min_yrs_of_exp"
                        :min="0"
                        :max="30"
                        :value="jobs.min_yrs_of_exp || 0"
                        @change="handleSliderMinExp"
                      ></v-slider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Languages</label>
                    <div class="col infoicon-multi-select">
                      <ValidationProvider v-slot="{ errors }">
                        <Select
                          name="language_ids"
                          :value="jobs.language_ids"
                          @input="handleChangeSelect"
                          :options="
                            options && options['language']
                              ? options['language']
                              : []
                          "
                          :taggable="false"
                          :multiple="true"
                          :clearable="true"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol v-if="!isNonClinicAdmin" md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Local License or Eligibility Required
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="regional_license"
                        :value="jobs.regional_license"
                        :options="
                          options && options['regional_license']
                            ? options['regional_license']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol class="mb-3 col-md-6">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Gender</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="gender_id"
                        :value="jobs.gender_id"
                        :options="
                          options && options['gender'] ? options['gender'] : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Preferred Nationality
                    </label>
                    <div class="col infoicon-multi-select">
                      <Select
                        name="pref_nationality"
                        :value="jobs.pref_nationality"
                        @input="handleMultiSelectArrayForCountries"
                        :options="
                          options && options['nationality']
                            ? options['nationality']
                            : []
                        "
                        :taggable="false"
                        :multiple="true"
                        :clearable="true"
                      />
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Maximum age ({{ jobs.maximum_age }} yrs)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <v-slider
                        name="maximum_age"
                        :min="20"
                        :max="70"
                        :value="jobs.maximum_age"
                        @change="handleSliderMaxAge"
                      ></v-slider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="d-flex justify-content-end">
                <CButton
                  class="btn-primary rounded-2 small mr-3"
                  @click="showAdvertModal"
                >
                  Add to Advert
                </CButton>
              </CRow>
              <hr />
              <h5 class="text-primary">Budgets & Positions</h5>
              <CRow class="mt-3">
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12"> Total Budget </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <CRow>
                        <CCol class="pr-0" style="max-width: 50%">
                          <ValidationProvider
                            rules="currencyCheck"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="allocated_budget"
                              :value="jobs.allocated_budget"
                              :min="0"
                              type="Number"
                              CSS_Style="max-height: 31px"
                              @change="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </CCol>
                        <CCol class="p-0 ml-1" style="max-width: 25%">
                          <Select
                            name="allocated_budget_currency_id"
                            :value="jobs.allocated_budget_currency_id"
                            @change="handleChangeSelect"
                            :options="
                              options && options['currency']
                                ? options['currency']
                                : []
                            "
                            :taggable="false"
                            :clearable="false"
                          />
                        </CCol>
                      </CRow>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      No. of Positions Available
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        name="positions_available"
                        :rules="{}"
                        v-slot="{ errors }"
                      >
                        <TextInput
                          name="positions_available"
                          type="Number"
                          :min="0"
                          :value="jobs.positions_available"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
            <!-- Main Section Ends -->
          </CCard>
          <!-- Card Ends -->
        </CCardGroup>
        <div class="border-top d-flex justify-content-between py-3 flex-wrap">
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary"
              @click="$parent.goToJobBoard()"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              name="draft"
              class="btn mr-2 rounded-2 px-5 btn-primary btn-lg mt-3"
              type="button"
              @click="onSave('draft')"
              :disabled="isLoading"
            >
              <CSpinner
                v-if="isLoading == 'draft'"
                class="spinner-border-sm text-white m-1"
              />
              {{ isEditJob ? "Save & Exit" : "Save as Draft" }}
            </button>
            <button
              name="next"
              class="btn rounded-2 px-5 btn-primary btn-lg mt-3"
              type="button"
              @click="onSave('next')"
              :disabled="isLoading"
            >
              {{ isEditJob ? "Save & Continue" : "Next" }}
              <CSpinner
                v-if="isLoading == 'next'"
                class="spinner-border-sm text-white m-1"
              />
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <AdvertModal :isShowPopup="isShowPopup" :jobData="jobs" />
    <CModal
      color="primary"
      :show.sync="confirmationModal.isShowPopup"
      v-if="confirmationModal.isShowPopup"
    >
      <template #header>
        <h5>NOTIFICATION</h5>
      </template>
      <div>
        <p>
          You selected
          <strong>{{ jobs.organisation.label }}’s Hospital</strong>.
          <br />
          Therefore this job will use the following options:
        </p>
        <ul>
          <li>logo and branding</li>
          <li>talent pool for candidate matching</li>
          <li>job board</li>
        </ul>
        <br />
        <p>
          You can also select to publish on the Group job board etc in later
          screens. Or, you can select only the Group options by changing the
          Organisation in the first field.<br /><br />
          Please confirm you are OK to proceed.
        </p>
      </div>
      <template #footer>
        <div>
          <span v-for="button in ['Cancel', 'Confirm']" :key="button">
            <CButton
              class="ml-2"
              :color="button == 'Cancel' ? 'secondary' : 'primary'"
              @click="confirmationModalCallBack(button)"
            >
              {{ button }}
            </CButton>
          </span>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import m from "moment";
import AddJob from "./AddJob";
import { mapActions, mapGetters } from "vuex";
import AdvertModal from "./JobInfoAdvertModal";
import isJobSupplier from "@/mixins/isJobSupplier";
import { isObject, CONSTANT as CONST } from "@/helpers/helper";

export default {
  mixins: [AddJob, isJobSupplier],
  components: {
    AdvertModal,
  },
  data() {
    return {
      CONST,
      jobs: {
        pref_nationality: [{ label: "All", code: 0 }],
        qualificationRegion: [{ label: "All", code: 0 }],
        qualificationCountry: [{ label: "All", code: 0 }],
        maximum_age: 58,
        regional_license: "n/a",
        gender_id: null,
        language_ids: [{ label: "English", code: 1 }],
        preferred_in_advert: [],
        other_areas_of_expertise_ids: [],
        experience_in_years: null,
        current_nhs_band_ids: [{ label: "All", code: 0 }],
      },
      payload: {},
      isShowPopup: false,
      isLoading: false,
      isEditJob: false,
      job_approvers: [],
      candidate_reviewers: [],
      candidate_interviewers: [],
      job_contact_recruiters: [],
      job_contact_recruiters_for_UK_main: [],
      confirmationModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getUser", "isJobSupplierFromAccessToken", "getCurrency"]),
    // Setting Initial Values
    isFetchingJobData() {
      if (Object.keys(this.jobById).length) {
        let jobData = this.jobById;
        const currency = jobData?.location?.country?.currency;
        this.jobs = {
          ...this.jobById,
          allocated_budget_currency_id: jobData?.allocated_budget_currency_id
            ? this.getCurrencyForSelectedID(
                jobData?.allocated_budget_currency_id
              )
            : {
                label: currency.currency_code,
                code: currency.currency_id,
              },
          organisation: jobData.organisation
            ? {
                label: jobData.organisation.name,
                code: jobData.organisation.organisation_id,
                organisation: jobData.organisation,
              }
            : null,
          location_id: jobData.location_id
            ? {
                label: jobData.location?.location,
                code: jobData.location_id,
                country_id: jobData.location?.country_id,
              }
            : null,
          candidateType: jobData.candidate_type
            ? {
                label: jobData.candidate_type?.candidate_type,
                code: jobData.candidate_type_id,
              }
            : null,
          level: jobData.level_ids?.length
            ? this.getLevelForSelectedId(jobData?.level_ids)
            : null,
          speciality: jobData.specialities?.length
            ? jobData.specialities
                ?.filter((val) => val.is_current || val.is_current === null)
                ?.map((val) => {
                  return {
                    label:
                      jobData.candidate_type_id == CONST.NURSE
                        ? `${val.speciality?.speciality}${
                            val.sub_speciality?.sub_speciality
                              ? ` - ${val.sub_speciality?.sub_speciality}`
                              : ""
                          }`
                        : val.speciality?.speciality,
                    code: val.speciality_id,
                    speciality_id: val?.speciality_id,
                    speciality_name: val?.speciality?.speciality,
                    sub_speciality_id: val?.sub_speciality_id,
                    sub_speciality: val?.sub_speciality?.sub_speciality,
                  };
                })[0]
            : null,
          sub_speciality:
            jobData.specialities
              ?.filter(
                (val) =>
                  (val.is_current || val.is_current === null) &&
                  val.sub_speciality_id
              )
              ?.map((val) => {
                return {
                  label:
                    jobData.candidate_type_id == CONST.NURSE
                      ? `${val.speciality?.speciality}${
                          val.sub_speciality?.sub_speciality
                            ? ` - ${val.sub_speciality?.sub_speciality}`
                            : ""
                        }`
                      : val.sub_speciality?.sub_speciality,
                  code: val.sub_speciality_id,
                  speciality_id: val?.speciality_id,
                  speciality_name: val?.speciality?.speciality,
                  sub_speciality_id: val?.sub_speciality_id,
                  sub_speciality: val?.sub_speciality?.sub_speciality,
                };
              })[0] || null,
          temp_experience_in_years: jobData?.specialities
            ? jobData.specialities?.filter(
                (val) => val.is_current || val.is_current === null
              )[0]?.experience_in_years
            : null,
          experience_in_years: jobData.specialities
            ? jobData.specialities?.filter(
                (val) => val.is_current || val.is_current === null
              )[0]?.experience_in_years
            : null,
          temp_other_areas_of_expertise_ids:
            jobData.specialities?.length > 1
              ? jobData.specialities
                  ?.filter((val) => val.is_current === false)
                  .map((val) => {
                    return {
                      label: `${val.speciality?.speciality}${
                        val.sub_speciality?.sub_speciality
                          ? ` - ${val.sub_speciality?.sub_speciality}`
                          : ""
                      }`,
                      code: val.speciality_id,
                      speciality_id: val?.speciality_id,
                      speciality_name: val?.speciality?.speciality,
                      sub_speciality_id: val?.sub_speciality_id,
                      sub_speciality: val?.sub_speciality?.sub_speciality,
                    };
                  })
              : [],
          other_areas_of_expertise_ids:
            jobData.specialities?.length > 1
              ? jobData.specialities
                  ?.filter((val) => val.is_current === false)
                  .map((val) => {
                    return {
                      label: `${val.speciality?.speciality}${
                        val.sub_speciality?.sub_speciality
                          ? ` - ${val.sub_speciality?.sub_speciality}`
                          : ""
                      }`,
                      code: val.speciality_id,
                      speciality_id: val?.speciality_id,
                      speciality_name: val?.speciality?.speciality,
                      sub_speciality_id: val?.sub_speciality_id,
                      sub_speciality: val?.sub_speciality?.sub_speciality,
                    };
                  })
              : [],
          special_interest_ids:
            jobData.specialities
              ?.filter((fil) => fil.is_current || fil.is_current === null)[0]
              ?.job_special_interest?.map((val) => {
                return {
                  label: val.special_interest,
                  code: val.special_interest_id,
                  typeId: val.speciality_id,
                  candidateTypeId: val.candidate_type_id,
                };
              }) || null,
          job_speciality_id: jobData.specialities[0]?.job_speciality_id,
          customer_organisation_contact_id:
            jobData.customer_organisation_contact_id
              ? this.getOrgContactForSelectedId({
                  customer_organisation_contact_id:
                    jobData.customer_organisation_contact_id,
                })
              : [],
          job_contact_recruiters: jobData.job_contact_recruiters?.length ?
            jobData.job_contact_recruiters?.map((val) => ({
              label: `${val.customer_recruiter?.user?.first_name} ${
                val.customer_recruiter?.user?.surname
              } ${
                val.customer_recruiter?.user?.org_detail?.org_name
                  ? `(${val.customer_recruiter?.user?.org_detail?.org_name})`
                  : ""
              }`,
              code: val.customer_recruiter_id,
            }))[0] : null,
          job_approval:
            jobData.job_users
              ?.filter((val) => {
                return val?.customer_user?.customer_user_type_id == 4;
              })
              .map((val) => ({
                label: `${val.customer_user?.user?.first_name} ${
                  val.customer_user?.user?.surname
                } ${
                  val.customer_user?.user?.org_detail?.org_name
                    ? `(${val.customer_user?.user?.org_detail?.org_name})`
                    : ""
                }`,
                code: val.customer_user_id,
              })) || null,
          candidate_reviewer:
            jobData.job_candidate_users
              ?.filter((val) => {
                return val?.customer_user?.customer_user_type_id == 1;
              })
              .map((val) => ({
                label: `${val.customer_user?.user?.first_name} ${
                  val.customer_user?.user?.surname
                } ${
                  val.customer_user?.user?.org_detail?.org_name
                    ? `(${val.customer_user?.user?.org_detail?.org_name})`
                    : ""
                }`,
                code: val.customer_user_id,
              })) || null,
          candidate_interviewer:
            jobData.job_candidate_users
              ?.filter((val) => {
                return val?.customer_user?.customer_user_type_id == 2;
              })
              .map((val) => ({
                label: `${val.customer_user?.user?.first_name} ${
                  val.customer_user?.user?.surname
                } ${
                  val.customer_user?.user?.org_detail?.org_name
                    ? `(${val.customer_user?.user?.org_detail?.org_name})`
                    : ""
                }`,
                code: val.customer_user_id,
              })) || null,
          qualificationRegion: jobData.qualification_region_ids
            ? this.getRegionNameForSelectedId(jobData.qualification_region_ids)
            : [{ label: "All", code: 0 }],
          qualificationCountry: jobData.qualification_country_ids
            ? this.getCountryNameForSelectedId(
                jobData.qualification_country_ids
              )
            : [{ label: "All", code: 0 }],
          min_qualification_level_id: jobData.min_qualification_level_id
            ? {
                label:
                  jobData.min_qualification_level?.qualification_classification,
                code: jobData.min_qualification_level
                  ?.qualification_classification_id,
              }
            : null,
          gender_id: jobData.gender_id || null,
          pref_nationality: jobData.countries_included?.length
            ? jobData.countries_included.map(({ country }) => {
                return {
                  label: country.nationality,
                  code: country.country_id,
                };
              })
            : [{ label: "All", code: 0 }],
          expiry_review_date: jobData.expiry_review_date,
          regional_license: jobData.regional_license || "n/a",
          language_ids: jobData.language_ids
            ? this.getLanguageForSelectedId(jobData.language_ids)
            : null,
          min_yrs_of_exp: jobData.min_yrs_of_exp,
          preferred_in_advert: jobData.preferred_in_advert,
          current_nhs_band_ids: jobData.current_nhs_bands?.length
            ? jobData.current_nhs_bands.map((val) => {
                return {
                  label: val.band_name,
                  code: val.band_id,
                };
              })
            : [{ label: "All", code: 0 }],
        };
      }
      return true;
    },
    options() {
      return {
        currency: this.getCurrency || [],
        gender: [
          { id: 1, label: "Male" },
          { id: 2, label: "Female" },
          { id: null, label: "Any" },
        ],
        regional_license: [
          { id: "required", label: "Required" },
          { id: "preferred", label: "Preferred" },
          { id: "n/a", label: "N/A" },
        ],
        candidateType: this.candidateType || [],
        level_id:
          this.candidateLevelByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: this.jobs?.location_id?.country_id,
          }) || [],
        organisation: this.organizationId || [],
        agencyAndPartnerHospitals: this.agencyAndPartnerHospitals || [],
        location_id:
          this.getLocationByOrganisation(this.jobs?.organisation?.code) || [],
        speciality:
          this.specialityByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: null,
          }) || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.jobs?.speciality?.code,
          }) || [],
        nurseSubSpeciality:
          this.nurseSubSpeciality(this.getNurseSubSpeciality, "speciality") ||
          [],
        nurseOtherAreaExp:
          this.nurseSubSpeciality(
            this.getNurseSubSpeciality,
            "other_areas_of_expertise_ids"
          ) || [],
        nationality:
          [
            { code: 0, label: "All" },
            ...this.getNationalityCountryInfo.map(
              ({ nationality, country_id }) => ({
                code: country_id,
                label: nationality,
              })
            ),
          ] || [],
        qualification_region:
          this.sortOrganisationRegions(this.getOrganisationRegions) || [],
        qualification_country: this.getQualificationCountryByRegion,
        language: this.locationLanguageReOrder,
        special_interest: this.getFilteredSpecialInterest
          ? this.getFilteredSpecialInterest.filter(
              (val) => val?.code !== "Other"
            )
          : [],
        candidate_reviewer: this.candidate_reviewer_options,
        candidate_interviewer: this.candidate_interviewer_options,
        job_approval: this.uk_main_agency_and_job_supplier
          ? this.job_approvers_options_for_UK_main_options
          : this.job_approvers_options,
        customer_organisation_contact: this.sortByLabel(
          this.getOrgContacts || []
        ),
        job_contact_recruiters: this.job_contact_recruiters_options || [],
        job_contact_recruiters_for_UK_main:
          this.job_contact_recruiters_options_for_UK_main_options || [],
        current_nhs_bands: this.getCandidateBands || [],
        min_qualification_level:
          this.getQualificationClassification(this.jobs?.candidateType?.code) ||
          [],
      };
    },
    uk_main_agency_and_job_supplier() {
      return this.isUKMainAgency || this.isJobSupplierFromAccessToken;
    },
    job_contact_recruiters_options() {
      this.job_contact_recruiters = this.getJobContactRecruiters();
      return this.job_contact_recruiters;
    },
    // for Uk Main Agency Customers
    job_contact_recruiters_options_for_UK_main_options() {
      this.job_contact_recruiters_for_UK_main =
        this.getJobContactRecruiters()?.filter((val) =>
          this.jobs?.organisation?.organisation?.organisation_type_id != 3
            ? val?.inherited
            : true
        );
      return this.job_contact_recruiters_for_UK_main;
    },
    job_approvers_options_for_UK_main_options() {
      return this.job_approvers_options?.length
        ? _(this.job_approvers_options)
            .filter((val) =>
              this.jobs?.organisation?.organisation?.organisation_type_id != 3
                ? val?.inherited
                : true
            )
            .value()
        : [];
    },
    // --end  for Uk Main Agency Customers
    job_approvers_options() {
      this.job_approvers = this.getContactUserByTypeForJob({
        customer_user_type_id: 4,
      });
      return this.jobs.job_approval &&
        this.jobs.job_approval?.length &&
        this.job_approvers &&
        this.job_approvers.length
        ? this.job_approvers.filter(
            (val) =>
              !this.jobs?.job_approval?.some(({ code }) => code === val?.code)
          )
        : this.job_approvers;
    },
    candidate_reviewer_options() {
      this.candidate_reviewers = this.getContactUserByTypeForJob({
        customer_user_type_id: 1,
      });
      return this.jobs.candidate_reviewer &&
        this.jobs.candidate_reviewer?.length &&
        this.candidate_reviewers &&
        this.candidate_reviewers.length
        ? this.candidate_reviewers.filter(
            (val) =>
              !this.jobs?.candidate_reviewer?.some(
                ({ code }) => code === val?.code
              )
          )
        : this.candidate_reviewers;
    },
    candidate_interviewer_options() {
      this.candidate_interviewers = this.getContactUserByTypeForJob({
        customer_user_type_id: 2,
      });
      return this.jobs.candidate_interviewer &&
        this.jobs?.candidate_interviewer?.length &&
        this.candidate_interviewers &&
        this.candidate_interviewers.length
        ? this.candidate_interviewers.filter(
            (val) =>
              !this.jobs?.candidate_interviewer?.some(
                ({ code }) => code === val?.code
              )
          )
        : this.candidate_interviewers;
    },
    locationLanguageReOrder() {
      return (
        this.locationLanguage.length &&
        this.locationLanguage.sort((a, b) => {
          return a.code === 1 ? -1 : 1;
        })
      );
    },
    // Returns if job has been created
    isJobCreated() {
      return this.jobs.job_id ? true : false;
    },
    // Returns if Candidate type is Physician
    isPhysician() {
      return this.jobs.candidateType?.code == CONST.PHYSICIAN;
    },
    // Returns if Candidate type is Nurse
    isNurse() {
      return this.jobs.candidateType?.code == CONST.NURSE;
    },
    // Returns if Candidate type is AHP
    isAHP() {
      return this.jobs.candidateType?.code == CONST.AHP;
    },
    // Returns if Candidate type is Non Clinic Admin
    isNonClinicAdmin() {
      return this.jobs.candidateType?.code == CONST.NON_CLINIC_ADMIN;
    },
    // Returns if organisation is child
    isChildOrg() {
      return this.jobs.organisation?.organisation?.parent_org_id ? true : false;
    },
  },
  watch: {
    getOrganisationRegions() {
      this.checkOptionsAndValuesForMultiSelect(
        "qualificationRegion",
        "qualification_region"
      );
    },
    getQualificationCountryByRegion() {
      this.checkOptionsAndValuesForMultiSelect(
        "qualificationCountry",
        "qualification_country"
      );
    },
    "options.level_id"() {
      this.checkOptionsAndValues("level", "level_id");
      if (!this.isAHP) {
        this.$refs.level?.errors.clear;
        this.$refs.level?.reset();
      }
    },
    "options.job_contact_recruiters_for_UK_main"() {
      if (
        !this.jobs?.job_contact_recruiters &&
        this.options["job_contact_recruiters_for_UK_main"] &&
        this.options["job_contact_recruiters_for_UK_main"].length == 1
      ) {
        this.handleChangeSelect(
          "job_contact_recruiters",
          this.options["job_contact_recruiters_for_UK_main"][0]
        );
      }
    },
    "options.job_approval"() {
      if (
        !this.jobs?.job_approval &&
        this.options["job_approval"] &&
        this.options["job_approval"].length == 1
      ) {
        this.handleChangeSelect(
          "job_approval",
          this.options["job_approval"][0]
        );
      }
    },
    "options.candidate_reviewer"() {
      if (
        !this.jobs?.candidate_reviewer &&
        this.options["candidate_reviewer"] &&
        this.options["candidate_reviewer"].length == 1
      ) {
        this.handleChangeSelect(
          "candidate_reviewer",
          this.options["candidate_reviewer"][0]
        );
      }
    },
    "options.candidate_interviewer"() {
      if (
        !this.jobs?.candidate_interviewer &&
        this.options["candidate_interviewer"] &&
        this.options["candidate_interviewer"].length == 1
      ) {
        this.handleChangeSelect(
          "candidate_interviewer",
          this.options["candidate_interviewer"][0]
        );
      }
    },
    "options.customer_organisation_contact"() {
      if (
        !this.jobs?.customer_organisation_contact_id &&
        this.options["customer_organisation_contact"] &&
        this.options["customer_organisation_contact"].length == 1
      ) {
        this.handleChangeSelect(
          "customer_organisation_contact_id",
          this.options["customer_organisation_contact"][0]
        );
      }
    },
    "options.special_interest"() {
      this.checkOptionsAndValues("special_interest_ids", "special_interest");
    },
    "options.job_contact_recruiters"() {
      if (!this.jobById?.job_contact_recruiters?.length && this.getUser) {
        const recruiter_roles = this.getUser?.recruiters_roles?.length
          ? this.getUser?.recruiters_roles
          : [];
        const first_name = this.getUser?.first_name;
        const last_name = this.getUser?.surname;
        let default_value =
          _(recruiter_roles)
            .filter((role) => {
              if (
                this.options?.job_contact_recruiters?.length &&
                role.customer_recruiter_id
              ) {
                return _.filter(
                  this.options?.job_contact_recruiters,
                  (option) => {
                    option.code == role.customer_recruiter_id;
                  }
                );
              }
            })
            .map((role) => {
              return {
                label: `${first_name} ${last_name}`,
                code: role.customer_recruiter_id,
              };
            })
            .value() || [];
        if (default_value && this.jobs && !this.jobs.job_contact_recruiters) {
          this.jobs.job_contact_recruiters = default_value[0];
          this.payload.job_contact_recruiters = default_value[0]?.code;
        } else if (
          this.options["job_contact_recruiters"] &&
          this.options["job_contact_recruiters"].length == 1
        ) {
          this.handleChangeSelect(
            "job_contact_recruiters",
            this.options["job_contact_recruiters"][0]
          );
        }
      }
    },
    jobs() {
      this.$emit("tempJob", this.jobs);
    },
  },
  mounted() {
    // Set default values for organisation and location
    if (!Object.keys(this.jobById).length && !this.getIsParentUser) {
      this.handleChangeSelect("organisation", this.options["organisation"][0]);
      this.handleChangeSelect("location_id", this.options["location_id"][0]);
    }
    // Set Edit job = true if jobId is passed
    if (this.$router.currentRoute.name == "EditJob") this.isEditJob = true;
  },
  methods: {
    ...mapActions(["createJobSpeciality"]),
    handleSliderMaxAge: debounce(function (value) {
      Vue.set(this.jobs, "maximum_age", value);
      Vue.set(this.payload, "maximum_age", value);
    }, 500),
    handleSliderMinExp: debounce(function (value) {
      Vue.set(this.jobs, "min_yrs_of_exp", value);
      Vue.set(this.payload, "min_yrs_of_exp", value);
    }, 500),
    handleSliderMinExpSpeciality: debounce(function (value) {
      Vue.set(this.jobs, "experience_in_years", value);
      Vue.set(this.payload, "experience_in_years", value);
    }, 500),
    handleCheckBox(name, value) {
      Vue.set(this.payload, name, value);
    },
    sortByLabel(data) {
      return data?.sort((a, b) => {
        a = a.label.toLowerCase();
        b = b.label.toLowerCase();
        if (a < b) return -1;
        else if (a > b) return 1;
        return 0;
      });
    },
    sortOrganisationRegions(data) {
      data =
        data?.sort((a, b) => {
          if (a.display_order === b.display_order) return 0;
          else if (a.display_order === null) return 1;
          else if (b.display_order === null) return -1;
          if (a.display_order < b.display_order) return -1;
          else if (a.display_order > b.display_order) return 1;
          return 0;
        }) || [];
      return [{ code: 0, label: "All" }, ...data];
    },
    nurseSubSpeciality(data, field) {
      let nurseSubSpeciality = [];
      if (data?.length) {
        let ids = data.map((o) => o.speciality_id);
        nurseSubSpeciality = data.filter(
          ({ speciality_id }, index) => !ids.includes(speciality_id, index + 1)
        );
        nurseSubSpeciality = nurseSubSpeciality?.map((val) => ({
          label: val?.speciality_name,
          code: val?.speciality_id,
          sub_speciality_id: null,
          sub_speciality: "",
          speciality_id: val?.speciality_id,
          speciality_name: val?.speciality_name,
          isGroup: true,
        }));
      }
      data = [...data, ...nurseSubSpeciality];
      const checkingField = {
        speciality: "other_areas_of_expertise_ids",
        other_areas_of_expertise_ids: "speciality",
      };
      if (field && checkingField[field] && this.jobs[checkingField[field]]) {
        const val = this.jobs[checkingField[field]];
        if (val && Array.isArray(val)) {
          const groupSpl = val
            .filter(({ isGroup }) => isGroup)
            .map(({ code }) => code);
          data = data.filter((v) => !groupSpl.includes(v?.code));
        } else if (val?.isGroup) {
          data = data.filter((v) => v?.code != val?.code);
        }
      }
      return data?.length
        ? this.sortByGroupLabel(data).map((v) => ({
            ...v,
            label: v?.isGroup
              ? `${v?.label} (All areas of expertise)`
              : v?.label,
          }))
        : [];
    },
    sortByGroupLabel(data) {
      return (
        data &&
        data.sort((a, b) => {
          const aLabel = a.label.toLowerCase();
          const bLabel = b.label.toLowerCase();
          if (aLabel < bLabel && a?.isGroup && !b?.isGroup) return -1;
          else if (aLabel > bLabel && b?.isGroup && !a?.isGroup) return 1;
          return 0;
        })
      );
    },
    checkOptionsAndValues(name, option) {
      let data = [];
      if (this.jobs[name]?.length) {
        let notFound = false;
        this.jobs[name].forEach((val) => {
          let hasFound = false;
          this.options[option].some((options) => options.code == val.code)
            ? (hasFound = true)
            : (notFound = true);
          if (hasFound) data.push(val);
        });
        if (notFound) this.handleChangeSelect(name, data);
      }
    },
    checkOptionsAndValuesForMultiSelect(name, option) {
      let data = [];
      if (this.jobs[name]?.length) {
        this.jobs[name].forEach((val) => {
          let hasFound = this.options[option].some(
            (options) => options.code == val.code
          );
          if (hasFound) data.push(val);
        });
        this.handleMultiSelectArrayForCountries(name, data);
      }
    },
    onStep(step) {
      this.$emit("step", step);
    },
    confirmationModalCallBack(action) {
      if (action == "Cancel") this.jobs.organisation = {};
      else this.$emit("showHintOrgName", this.jobs.organisation?.label);
      this.confirmationModal.isShowPopup = false;
    },

    async onSave(selectedBtn) {
      const isValid = await this.$refs.addJobInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      this.isLoading = selectedBtn;
      // IF condition #1 Starts
      if (Object.keys(this.payload).length) {
        let approvers = isObject(this.jobs?.job_approval)
          ? [this.jobs?.job_approval]
          : Array.isArray(this.jobs?.job_approval)
          ? this.jobs?.job_approval
          : [];
        let reviewer = isObject(this.jobs?.candidate_reviewer)
          ? [this.jobs?.candidate_reviewer]
          : Array.isArray(this.jobs?.candidate_reviewer)
          ? this.jobs?.candidate_reviewer
          : [];
        let interviewer = isObject(this.jobs?.candidate_interviewer)
          ? [this.jobs?.candidate_interviewer]
          : Array.isArray(this.jobs?.candidate_interviewer)
          ? this.jobs?.candidate_interviewer
          : [];
        let job_approvers =
          approvers && approvers?.length
            ? approvers?.map((val) => val.code)
            : [];
        let job_users = job_approvers
          ?.concat(reviewer?.map((val) => val.code) || [])
          .concat(interviewer?.map((val) => val.code) || []);
        let finalPayload = {
          job_id: this.jobs.job_id,
          customer_uid: this.jobs.customer_uid,
          organisation: this.jobs.organisation?.code,
          published: false,
          candidate_type_id: this.jobs?.candidateType?.code,
          level_ids: this.jobs.level?.map((val) => val.code) || null,
          job_title: this.jobs.job_title,
          speciality_id: this.jobs.speciality?.code,
          sub_speciality_id: this.jobs.sub_speciality?.code || null,
          job_candidate_users: job_users,
          qualification_region_ids:
            this.jobs.qualificationRegion[0].label != "All"
              ? this.jobs.qualificationRegion?.map((val) => val.code)
              : null,
          qualification_country_ids:
            this.jobs.qualificationCountry[0].label != "All"
              ? this.jobs.qualificationCountry?.map((val) => val.code)
              : null,
          qualification_regions_all:
            this.jobs.qualificationRegion[0].label != "All" ? null : true,
          qualification_countries_all:
            this.jobs.qualificationCountry[0].label != "All" ? null : true,
          location_id: this.jobs.location_id?.code || null,
          gender_id: this.jobs.gender_id || null,
          maximum_age: this.jobs.maximum_age || null,
          countries_included:
            this.jobs.pref_nationality[0].label != "All"
              ? this.jobs.pref_nationality
              : null,
          language_ids: this.jobs.language_ids?.map((val) => val.code),
          regional_license: this.jobs.regional_license,
          min_yrs_of_exp: this.jobs.min_yrs_of_exp || 0,
          expiry_review_date: m().add(30, "days").format("YYYY-MM-DD"),
          special_interest_ids: this.jobs.special_interest_ids?.length
            ? this.jobs.special_interest_ids?.map((val) => val.code)
            : null,
          preferred_in_advert: this.jobs.preferred_in_advert?.length
            ? this.jobs.preferred_in_advert.filter((val) => val)
            : null,
          other_areas_of_expertise_ids: this.jobs.other_areas_of_expertise_ids
            ?.length
            ? this.jobs.other_areas_of_expertise_ids?.map((val) => ({
                speciality_id: val.speciality_id || val.code || null,
                sub_speciality_id: val.sub_speciality_id || null,
              }))
            : null,
          experience_in_years: this.jobs.experience_in_years || null,
          customer_organisation_contact_id:
            this.jobs.customer_organisation_contact_id?.code,
          job_contact_recruiters: Array.isArray(
            this.jobs.job_contact_recruiters
          )
            ? this.jobs.job_contact_recruiters?.map((val) => val.code)
            : this.jobs.job_contact_recruiters?.code
            ? [this.jobs.job_contact_recruiters?.code]
            : [],
          current_nhs_band_ids:
            this.isNurse &&
            this.jobs.location_id.country_id == 182 &&
            this.jobs.current_nhs_band_ids[0].label != "All"
              ? this.jobs.current_nhs_band_ids?.map((val) => val.code)
              : null,
          min_qualification_level_id:
            this.isPhysician || this.isNonClinicAdmin
              ? this.jobs.min_qualification_level_id?.code || null
              : null,
          allocated_budget: this.jobs.allocated_budget || null,
          positions_available: this.jobs.positions_available || null,
          allocated_budget_currency_id: this.jobs.allocated_budget
            ? this.jobs.allocated_budget_currency_id?.code
            : null,
          display_location:this.jobs.display_location,
        };
        // Call editJob Method if existing job
        if (this.jobById && this.jobById.job_id) {
          this.editJobs(selectedBtn, finalPayload);
        } else {
          let appendAction = [];
          const {
            organisation,
            speciality_id,
            sub_speciality_id,
            special_interest_ids,
            countries_included,
            other_areas_of_expertise_ids,
            experience_in_years,
            ...rest
          } = finalPayload;
          this.addJobs({ ...rest, organisation }).then((res) => {
            const { job_id, customer_uid } = res.data;
            this.$parent.jobId = job_id;
            appendAction = this.createJobSpeciality({
              job_id,
              customer_uid,
              speciality_id,
              sub_speciality_id,
              special_interest_ids,
              other_areas_of_expertise_ids,
              experience_in_years,
            });
            if (countries_included && countries_included.length) {
              countries_included.forEach((item) => {
                appendAction = this.createJobCountry({
                  job_id,
                  customer_uid,
                  country: item,
                });
              });
            }
            Promise.all([appendAction]).then((final_res) => {
              this.fetchJobByID(job_id).then((response) => {
                this.isLoading = false;
                if (selectedBtn == "next") this.onStep(1);
                this.showToast({
                  class: "bg-success text-white",
                  message: "Saved successfully!",
                });
                this.payload = {};
              });
            });
          });
        }
      } // End of if #1
      else {
        if (!this.jobById?.job_id) {
          this.showToast({
            class: "bg-danger text-white",
            message: "Please fill mandatory fields!",
          });
          return;
        }
        if (selectedBtn == "draft") {
          this.isLoading = false;
          if (this.isEditJob) this.$router.back();
        }
        if (selectedBtn == "next") this.onStep(1);
      }
    },
    editJobs(selectedBtn, finalPayload) {
      const { job_id, customer_uid } = finalPayload;

      if (
        finalPayload.speciality != this.jobById.specialities[0].speciality_id ||
        finalPayload.sub_speciality !=
          this.jobById.specialities[0]?.sub_speciality_id ||
        finalPayload.special_interest_ids !=
          this.jobById.specialities[0]?.special_interest_ids ||
        finalPayload.other_areas_of_expertise_ids !=
          this.jobs?.temp_other_areas_of_expertise_ids ||
        finalPayload.experience_in_years != this.jobs?.temp_experience_in_years
      ) {
        let specialityPayload = {
          customer_uid,
          job_id,
          job_speciality_id: this.jobById.specialities.filter(
            (val) => val.is_current || val.is_current == null
          )[0].job_speciality_id,
          speciality_id: finalPayload.speciality_id,
          sub_speciality_id: finalPayload.sub_speciality_id,
          special_interest_ids: finalPayload.special_interest_ids,
          experience_in_years: finalPayload.experience_in_years,
          other_areas_of_expertise_ids:
            finalPayload.other_areas_of_expertise_ids,
        };
        this.updateJobSpeciality(specialityPayload);
      }

      const { countries_included } = finalPayload;
      let existing_country = this.jobById.countries_included;
      if (countries_included || existing_country) {
        let createCountry = [];
        let deleteCountry = [];
        if (countries_included?.length) {
          countries_included.forEach((ele) => {
            if (existing_country?.length) {
              existing_country.some((val) => ele.code == val.include_country_id)
                ? null
                : createCountry.push(ele);
              return;
            }
            createCountry.push(ele);
          });
        }
        if (existing_country?.length) {
          existing_country.forEach((ele) => {
            if (countries_included?.length) {
              countries_included.some(
                (val) => val.code == ele.include_country_id
              )
                ? null
                : deleteCountry.push(ele);
              return;
            }
            deleteCountry.push(ele);
          });
        }

        if (createCountry?.length || deleteCountry?.length) {
          let appendAction = [];
          createCountry.forEach((item) => {
            appendAction = this.createJobCountry({
              job_id,
              customer_uid,
              country: item,
            });
          });
          deleteCountry.forEach(({ job_country_include_id }) => {
            appendAction = this.deleteJobCountryById({
              job_id,
              customer_uid,
              job_country_include_id,
            });
          });
          Promise.all([appendAction]).then((res) => {
            this.fetchJobByID(job_id);
          });
        }
      }

      if (finalPayload && Object.keys(finalPayload).length) {
        const {
          organisation,
          speciality_id,
          sub_speciality_id,
          special_interest_ids,
          countries_included,
          ...rest
        } = finalPayload;
        this.updateJobSteps({ organisation, ...rest }).then((res) => {
          this.payload = {};
          this.isLoading = false;
          if (selectedBtn == "draft" && this.isEditJob) this.$router.back();
          if (selectedBtn == "next") this.onStep(1);
        });
      }
    },
    showAdvertModal() {
      this.isShowPopup = true;
    },
  },
};
</script>
