<template>
  <div class="pl-2">
    <CModal
      title="Edit Section Description"
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="xl"
    >
      <template #header>
        <h5>Edit Section Description</h5>
        <CButtonClose @click="modalCallBack(false)" />
      </template>

      <template #footer>
        <CButton color="secondary" @click="modalCallBack(false)">
          Cancel
        </CButton>
        <CButton color="primary" type="Submit" @click="submit">
          Confirm
        </CButton>
      </template>

      <div class="col-lg-12 col-md-12 col-sm-12">
        <RichTextEditor
          name="description"
          :value="description"
          @input="handleInput"
        />
      </div>
    </CModal>
  </div>
</template>
<script>
import Modal from "@/components/reusable/Modal";
import RichTextEditor from "@/components/reusable/Fields/RichTextEditor";
export default {
  extends: Modal,
  name: "EditJobSectionTemplates",
  props: ["modalCallBack", "sectionDesc"],
  components: {
    RichTextEditor,
  },
  data() {
    return {
      description: "",
    };
  },
  methods: {
    handleInput(name, value) {
      this.description = value;
    },
    submit() {
      this.modalCallBack(true, this.description);
    },
  },
  mounted() {
    // Replace used to remove unwanted code between two tags and Replace All used to remove the margin bottom of <p> tag
    this.description = this.sectionDesc
      ?.replace(/\>\s+\</g, "><")
      ?.replaceAll("<p>", '<p class="mb-0">');
  },
};
</script>
