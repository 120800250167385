<template>
  <div class="add-job pb-2">
    <LoadingOverlay v-if="!isFetchingJobData" />
    <CCardGroup class="has-loading-overlay">
      <CCard>
        <CCardBody class="p-3">
          <ValidationObserver ref="JobAdditionalInfo" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <h5 class="text-primary">Salary</h5>
              <CRow class="mt-3">
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Target Salary (Private)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <CRow>
                        <CCol class="pr-0" style="max-width: 50%">
                          <ValidationProvider
                            rules="currencyCheck"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="target_salary_text"
                              :value="jobs.target_salary_text"
                              :min="0"
                              CSS_Style="max-height: 31px"
                              @change="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </CCol>
                        <CCol class="p-0 ml-1" style="max-width: 28%">
                          <Select
                            name="target_salary_currency_id"
                            :value="jobs.target_salary_currency_id"
                            @change="handleChangeSelect"
                            :options="
                              options && options['currency']
                                ? options['currency']
                                : []
                            "
                            :taggable="false"
                            :clearable="false"
                          />
                        </CCol>
                      </CRow>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Salary Range (Min - Max)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <CRow>
                        <CCol class="pr-0" style="max-width: 30%">
                          <ValidationProvider
                            name="salary_min_text"
                            rules="currencyCheck"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="salary_min_text"
                              :value="jobs.salary_min_text"
                              placeholder="Min"
                              CSS_Style="max-height: 31px"
                              @change="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </CCol>
                        <CCol class="p-0" style="max-width: 30%">
                          <ValidationProvider
                            name="salary_max_text"
                            rules="currencyCheck|minMaxCheck:@salary_min_text"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="salary_max_text"
                              :value="jobs.salary_max_text"
                              placeholder="Max"
                              :min="0"
                              CSS_Style="max-height: 31px"
                              @change="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </CCol>
                        <CCol class="ml-1 p-0" style="max-width: 28%">
                          <Select
                            name="salary_currency_id"
                            :value="jobs.salary_currency_id"
                            @change="handleChangeSelect"
                            :options="
                              options && options['currency']
                                ? options['currency']
                                : []
                            "
                            :taggable="false"
                            :clearable="false"
                          />
                        </CCol>
                      </CRow>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow class="mt-3">
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Publish Salary Range
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="salary"
                        :value="jobs.salary"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <hr />

              <h5 class="text-primary">Additional Information</h5>
              <CRow class="mt-3">
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Full Time</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="full_time"
                        :value="jobs.full_time"
                        :options="
                          options && options['workingHours']
                            ? options['workingHours']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Part Time</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="part_time"
                        :value="jobs.part_time"
                        :options="
                          options && options['workingHours']
                            ? options['workingHours']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Permanent Work</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="permanent_work"
                        :value="jobs.permanent_work"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="3" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Temporary Work</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="temporary_work"
                        :value="jobs.temporary_work"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="3" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Visiting/Locum</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="visiting_locum"
                        :value="jobs.visiting_locum"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol class="mb-3 col-md-6" v-if="jobs.working_hours">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Working Hours (Per Week)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        :rules="{ max_value_hours: 48 }"
                        v-slot="{ errors }"
                      >
                        <TextInput
                          name="working_hours"
                          type="Number"
                          :min="0"
                          :max="48"
                          :value="
                            jobs.working_hours &&
                            jobs.working_hours.detail_number
                          "
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol class="mb-3 col-md-6" v-if="jobs.working_days">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Working Days (per week)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        :rules="{ max_value_days: 7 }"
                        v-slot="{ errors }"
                      >
                        <TextInput
                          class="text-box-no-spin"
                          name="working_days"
                          type="number"
                          :min="0"
                          :max="7"
                          :value="
                            jobs.working_days && jobs.working_days.detail_number
                          "
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow
                v-if="
                  jobs.call_on &&
                  jobs.call_on.detail_number != null &&
                  !isNurseOrAHP
                "
              >
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">On-Call Rota</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="call_on_radio"
                        :value="jobs.call_on_radio"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3" v-if="jobs.call_on_radio">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      On-call hours (optional)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextInput
                        name="call_on"
                        type="Number"
                        :value="jobs.call_on && jobs.call_on.detail_number"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Hospital or Clinic Based
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <Select
                        name="facility_type"
                        :value="jobs.facility_type"
                        @input="handleChangeSelect"
                        :options="
                          options && options['facility_type']
                            ? options['facility_type']
                            : []
                        "
                        :clearable="false"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Type of Contract / Length
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextInput
                        name="type_of_contract"
                        :value="jobs.type_of_contract"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Additional Information
                      <small>(Private)</small>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextareaInput
                        name="additional_info"
                        :value="jobs.additional_info"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Comments
                      <small>(Private)</small>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextareaInput
                        name="comments"
                        :value="jobs.comments"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </form>
          </ValidationObserver>
        </CCardBody>
      </CCard>
    </CCardGroup>
    <div
      class="row-sm my-2 action-group d-flex justify-content-between flex-wrap"
    >
      <div class="btn-add-job-cancel">
        <button
          name="cancel"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg"
          @click="$parent.goToJobBoard()"
        >
          Cancel
        </button>
      </div>
      <div class="btn-add-job-submit">
        <button
          name="Back"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
          @click="onSave('back', 0)"
          :disabled="isLoading"
        >
          Back
          <CSpinner
            v-if="isLoading == 'back'"
            class="spinner-border-sm text-white m-1"
          />
        </button>

        <button
          name="draft"
          type="button"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3 mr-2"
          @click="onSave('draft')"
          :disabled="isLoading"
        >
          {{ isEditJob ? "Save & Exit" : "Save & Draft" }}
          <CSpinner
            v-if="isLoading == 'draft'"
            class="spinner-border-sm text-white m-1"
          />
        </button>

        <button
          name="next_page"
          type="button"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3"
          @click="onSave('next', 2)"
          :disabled="isLoading"
        >
          {{ isEditJob ? "Save & Continue" : "Next" }}
          <CSpinner
            v-if="isLoading == 'next'"
            class="spinner-border-sm text-white m-1"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import AddJob from "./AddJob";
import { max_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { Role, getScope } from "@/helpers/helper";

extend("max_value_hours", {
  ...max_value,
  message: "Value cannot be greater than 48",
});
extend("max_value_days", {
  ...max_value,
  message: "Value cannot be greater than 7",
});
extend("minMaxCheck", {
  params: ["salary_min_text"],
  validate(value, { salary_min_text }) {
    value = value?.replaceAll(",", "");
    let salary_min = salary_min_text?.replaceAll(",", "");
    if (value - salary_min > 0) {
      return true;
    }
  },
  message: "Should be greater than min salary",
});
extend("currencyCheck", {
  validate(value) {
    let regex = /^[0-9.,]+$/;
    if (regex.test(value)) {
      return true;
    }
  },
  message: "Only numbers are allowed",
});

export default {
  mixins: [AddJob],
  props: {
    isJobSupplier: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      jobs: {},
      payload: {},
      isLoading: false,
      isEditJob: false,
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    ...mapGetters(["getOrgJobDetails", "getCurrency"]),
    isFetchingJobData() {
      if (Object.keys(this.jobById).length) {
        let jobs = this.jobById;

        const currency = jobs?.location?.country?.currency;
        this.jobs = {
          ...this.jobById,
          facility_type: jobs?.facility_type
            ? this.options.facility_type.filter(
                (fil) => fil.id == jobs?.facility_type
              )[0]
            : { id: "hospital", label: "Hospital" },
          target_salary_currency_id: jobs?.target_salary_currency_id
            ? this.getCurrencyForSelectedID(jobs?.target_salary_currency_id)
            : {
                label: currency.currency_code,
                code: currency.currency_id,
              },
          salary_currency_id: jobs?.salary_currency_id
            ? this.getCurrencyForSelectedID(jobs?.salary_currency_id)
            : {
                label: currency.currency_code,
                code: currency.currency_id,
              },
          salary: jobs?.preferred_in_advert?.includes("salary"),
        };

        let working_days = [];
        let working_hours = [];
        let call_on = [];
        let call_on_radio = null;
        let first_visit_working_hours = false;
        let first_visit_working_days = false;
        let first_visit_call_on = false;
        if (
          this.jobById?.job_details &&
          Object.keys(this.jobById?.job_details).length
        ) {
          working_days = this.jobById?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 1;
          });
          working_hours = this.jobById?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 2;
          });
          call_on = this.jobById?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 6;
          });
          if (call_on.length) {
            call_on_radio = true;
          }
        }
        if (Object.keys(this.getOrgJobDetails).length) {
          if (!working_days.length) {
            working_days = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 1;
            });
            if (working_days.length) {
              first_visit_working_days = true;
              working_days[0].detail_text = working_days[0].description;
              if (
                [this.Role.systemAdmin, this.Role.customerAdmin].includes(
                  this.currentUserRole
                )
              ) {
                working_days[0].detail_number = null;
              }
            }
          }
          if (!working_hours.length) {
            working_hours = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 2;
            });
            if (working_hours.length) {
              first_visit_working_hours = true;
              working_hours[0].detail_text = working_hours[0].description;
              if (
                [this.Role.systemAdmin, this.Role.customerAdmin].includes(
                  this.currentUserRole
                )
              ) {
                working_hours[0].detail_number = null;
              }
            }
          }
          // if (!call_on.length) {
          //   call_on = this.getOrgJobDetails.filter((element) => {
          //     return element.job_detail_type_id == 6;
          //   });
          //   if (call_on.length) {
          //     call_on[1].detail_text = `${call_on[1].detail_number} hour(s) working for Call-On-Rota`;
          //     first_visit_call_on = true;
          //   }
          // }
        }
        this.jobs = {
          ...this.jobs,
          working_days: working_days[0],
          working_hours: working_hours[0],
          call_on_radio: call_on_radio,
          call_on: call_on_radio ? call_on[0] : call_on[1],
          first_visit_working_days: first_visit_working_days,
          first_visit_working_hours: first_visit_working_hours,
          first_visit_call_on: first_visit_call_on,
        };
      }
      return true;
    },
    options() {
      return {
        workingHours: [
          { id: "yes", label: "Yes" },
          { id: "no", label: "No" },
          { id: "flexible", label: "Flexible" },
        ],
        facility_type: [
          { id: "hospital", label: "Hospital" },
          { id: "clinic", label: "Clinic" },
          { id: "n/a", label: "N/A" },
        ],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        currency: this.getCurrency || [],
      };
    },
    isNurseOrAHP() {
      return this.jobById?.candidate_type_id == 2 ||
        this.jobById?.candidate_type_id == 3
        ? true
        : false;
    },
  },
  mounted() {
    this.jobs = this.jobById;
    const {
      organisation_uid,
      part_time,
      full_time,
      temporary_work,
      permanent_work,
      visiting_locum,
    } = this.jobById;
    if (full_time == null) {
      this.handleChangeRadio("full_time", "yes");
    }
    if (part_time == null) {
      this.handleChangeRadio("part_time", "no");
    }
    if (permanent_work == null) {
      this.handleChangeRadio("permanent_work", true);
    }
    if (temporary_work == null) {
      this.handleChangeRadio("temporary_work", false);
    }
    if (visiting_locum == null) {
      this.handleChangeRadio("visiting_locum", false);
    }
    this.fetchOrgJobDetails({ organisation_id: organisation_uid });
    if (this.$router.currentRoute.name == "EditJob") this.isEditJob = true;
  },
  watch: {
    jobs() {
      this.$emit("tempJob", this.jobs);
    },
  },
  methods: {
    onStep(step) {
      this.$emit("step", step);
    },
    handleInput(name, value) {
      let data = value;
      if (name === "working_hours") {
        data = {
          ...this.jobs.working_hours,
          detail_number: value,
          detail_text: `${value} hour(s) working a week`,
        };
      } else if (name === "working_days") {
        data = {
          ...this.jobs.working_days,
          detail_number: value,
          detail_text: `${value} day(s) working week`,
        };
      } else if (name === "call_on") {
        data = {
          ...this.jobs.call_on,
          detail_number: value,
          detail_text: `${value} hour(s) working for Call-On-Rota`,
        };
      }
      Vue.set(this.jobs, name, data);
      // salary_min && salary_max accepts numbers and null value only - empty string is not accepted
      if (name == "salary_min_text" || name == "salary_max_text") {
        data = data || null;
      }
      if (
        this.jobById.salary_min_text != this.jobs.salary_min_text ||
        this.jobById.salary_max_text != this.jobs.salary_max_text ||
        this.jobById.target_salary_text != this.jobs.target_salary_text
      ) {
        this.payload.approval_required = true;
      } else if (!this.jobById.approval_required) {
        this.payload.approval_required = false;
      }
      Vue.set(this.payload, name, data);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.jobs, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    async onSave(selectedBtn, step) {
      const isValid = await this.$refs.JobAdditionalInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      if (
        this.jobs.first_visit_working_hours &&
        !this.payload.working_hours &&
        this.jobs.working_hours?.detail_number
      )
        this.payload.working_hours = this.jobs.working_hours;
      if (
        this.jobs.first_visit_working_days &&
        !this.payload.working_days &&
        this.jobs.working_days?.detail_number
      )
        this.payload.working_days = this.jobs.working_days;
      if (
        this.jobs.call_on_radio &&
        this.jobs.first_visit_call_on &&
        !this.payload.call_on
      )
        this.payload.call_on = this.jobs.call_on;
      if (this.payload?.target_salary_text)
        this.payload.target_salary_currency_id =
          this.jobs.target_salary_currency_id?.code;
      // Updating salary range if only both min and max is selected
      if (this.jobs?.salary_min_text || this.jobs?.salary_max_text) {
        if (!(this.jobs?.salary_min_text && this.jobs?.salary_max_text)) {
          this.payload.salary_min = null;
          this.payload.salary_max = null;
          this.payload.salary_min_text = null;
          this.payload.salary_max_text = null;
          //To update salary section template
          this.updateSections();
        }
        this.payload.salary_currency_id = this.jobs.salary_currency_id?.code;
      }
      if (this.payload.salary != undefined) {
        let advert = this.jobs.preferred_in_advert || [];
        if (this.payload.salary) {
          if (!advert?.includes("salary")) advert.push("salary");
        } else {
          advert = advert?.filter((e) => e != "salary");
          //To update salary section template
          this.updateSections();
        }
        this.payload.preferred_in_advert = advert?.length ? advert : null;
        delete this.payload["salary"];
      }
      this.submit(selectedBtn, step);
    },
    updateSections() {
      let job_desc_section_id = null;
      this.jobById?.job_desc_section_templates?.forEach((val) => {
        if (
          val?.section_template?.display_name.toLowerCase().includes("salary")
        )
          job_desc_section_id = val?.job_desc_section_id;
      });
      if (job_desc_section_id) {
        this.deleteJobDescSectionByID({
          customer_uid: this.jobById?.customer_uid,
          job_desc_section_id: job_desc_section_id,
        }).then((res) => {
          this.fetchJobByID(this.jobById?.job_id);
        });
      }
    },
    submit(selectedBtn, step) {
      this.isLoading = selectedBtn;
      const { job_id, customer_uid, organisation_uid } = this.jobById;
      if (Object.keys(this.payload).length) {
        let AdditionalPayload = [];
        let updatePayload = {};
        let payloadArray = Object.keys(this.payload);
        payloadArray.forEach((item) => {
          if (
            item === "working_hours" ||
            item === "working_days" ||
            item === "call_on"
          ) {
            AdditionalPayload.push(this.payload[item]);
          } else {
            updatePayload = {
              ...updatePayload,
              [item]: this.payload[item],
            };
          }
        });
        let appendAction = [];
        if (
          this.jobs.call_on_radio == false &&
          !this.jobs.first_visit_call_on
        ) {
          const { job_detail_id } = this.jobs.call_on;
          appendAction = this.deleteAdditionalInfo({
            job_id,
            job_detail_id,
          });
        }
        delete this.payload["call_on_radio"];

        if (AdditionalPayload?.length) {
          AdditionalPayload.forEach((val) => {
            const { organisation_job_detail_id } = val;
            let exist = this.jobById?.job_details?.some(
              (element) =>
                element.organisation_job_detail_id == organisation_job_detail_id
            );
            if (exist) {
              appendAction = this.updateAdditionalInfo({
                job_id,
                val: val,
              });
            } else {
              appendAction = this.createAdditionalInfo({
                job_id,
                val,
              });
            }
          });
        }
        if (Object.keys(updatePayload).length) {
          if (updatePayload.target_salary_text)
            updatePayload.target_salary = parseInt(
              updatePayload.target_salary_text.replaceAll(",", "")
            );
          if (updatePayload.salary_min_text || updatePayload.salary_max_text) {
            updatePayload = {
              ...updatePayload,
              salary_min: parseInt(
                this.jobs?.salary_min_text.replaceAll(",", "")
              ),
              salary_max: parseInt(
                this.jobs?.salary_max_text.replaceAll(",", "")
              ),
            };
          }
          let updatedPayload = {
            ...updatePayload,
            published: false,
            facility_type: this.jobs?.facility_type?.id || null,
            organisation: organisation_uid,
            job_id,
            customer_uid,
          };

          appendAction = this.updateJobSteps(updatedPayload);
        }
        Promise.all([appendAction]).then(async (res) => {
          this.fetchJobByID(job_id);
          this.isLoading = false;
          this.payload = {};
          if (selectedBtn == "draft" && this.isEditJob)
            this.$parent?.goToJobBoard();
          if (selectedBtn != "draft") this.onStep(step);
        });
      } else {
        this.isLoading = false;
        if (selectedBtn == "draft") {
          this.isLoading = false;
          if (this.isEditJob) this.$parent?.goToJobBoard();
        } else {
          this.onStep(step);
        }
      }
    },
  },
};
</script>
