<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Edit Benefit</h6>
        <CButtonClose @click="resetData()" class="text-black" />
      </template>
      <template #footer>
        <CButton class="btn px-4 btn-outline-primary" @click="resetData()"
          >Close</CButton
        >
        <CButton class="px-4 btn-primary" @click="onSubmit()">Update</CButton>
      </template>
      <div>
        <ValidationObserver ref="benefit" v-slot="{ handleSubmit }">
          <form id="benefit" @submit.prevent="handleSubmit()">
            <CRow class="mt-4">
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Benefit</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="benefit"
                      :value="benefitData.benefitType"
                      @input="handleInput"
                      :disabled="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" class="mb-3">
                <CRow class="row">
                  <label class="col-lg-12 col-md-12">Description</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextareaInput
                      name="description"
                      :value="benefitData.description"
                      @input="handleInput"
                      :disabled="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-4">
              <label class="col-lg-12 col-md-12">Published Comments</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <TextareaInput
                  name="comments"
                  :value="benefitData.comments"
                  @input="handleInput"
                />
              </div>
            </CRow>
            <CRow class="mb-4">
              <label class="col-lg-12 col-md-12">Private Comments</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <TextareaInput
                  name="comments_internal"
                  :value="benefitData.comments_internal"
                  @input="handleInput"
                />
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import AddJob from "./AddJob";
export default {
  name: "JobBenefitEditModal",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    benefitData: {
      type: Object,
      default: {},
    },
  },
  mixins: [AddJob],
  methods: {
    handleInput(name, value) {
      Vue.set(this.benefitData, name, value);
    },
    onSubmit() {
      this.$parent.tempBenefits[
        `benefit_${this.benefitData.organisation_job_benefit_id}`
      ] = this.benefitData;
      this.$parent.showBenefits = [];
      for (const [key, value] of Object.entries(this.$parent.tempBenefits)) {
        this.$parent.showBenefits.push(value);
      }
      this.resetData();
    },
    resetData() {
      this.$parent.isShowPopup = false;
    },
  },
};
</script>
