<template>
  <div class="add-job pb-2">
    <LoadingOverlay v-if="!fetchJobData || !this.jobById" />
    <ValidationObserver ref="JobTemplate" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit()">
        <CCardGroup class="has-loading-overlay">
          <CCard>
            <CCardBody class="p-3 pt-3">
              <div class="d-flex justify-content-between">
                <h5 class="main-title pb-3">Templates</h5>
              </div>
              <CRow
                class="justify-content-center m-0 mt-3 mb-3"
                style="width: 100%"
              >
                <div
                  class="text-center"
                  style="border: 1px dashed gray; width: 75%"
                >
                  <img
                    id="preview_img"
                    :src="getImageUrl"
                    @error="$event.target.src = '/img/job-image.jpg'"
                    style="width: 99%; max-height: 250px"
                    alt="job_picture"
                  />
                </div>
              </CRow>
              <div class="justify-content-center row">
                <image-slider
                  :class="`${
                    getJobPictures.length > 1
                      ? 'image-slider'
                      : 'single-image-slider'
                  }`"
                  :dataSet="getJobPictures"
                  backUpImage="/img/job-image.jpg"
                  cssClass="job-template-image"
                  @selectedImage="selectedJobImage"
                  :defaultImageID="getDefaultImageID"
                  :slidesToShow="
                    getJobPictures.length < 3 ? getJobPictures.length : 3
                  "
                />
              </div>
              <div class="mt-3" v-show="renderTemplates">
                <div class="mt-3" v-if="getItems.length">
                  <div class="select-sections-info">
                    <button class="hand-icon action-button" @click="showHint()">
                      <CIcon
                        name="cil-info"
                        v-c-tooltip="'Hint'"
                        class="text-primary"
                      />
                    </button>
                  </div>
                  <CDataTable
                    :items="getItems"
                    :fields="fields"
                    hover
                    class="table_"
                  >
                    <template #expand-header>
                      <div class="d-flex justify-content-end">
                        <a
                          class="text-primary"
                          style="font-weight: 500; cursor: pointer"
                          @click="expandAll(!expandAllSection)"
                        >
                          {{
                            expandAllSection ? "- Collapse All" : "+ Expand All"
                          }}
                        </a>
                      </div>
                    </template>

                    <template #expand="{ item, index }">
                      <td class="py-2 d-flex justify-content-end">
                        <a
                          class="text-primary"
                          style="font-weight: 500; cursor: pointer"
                          @click="toggleDetails(item, index)"
                        >
                          {{
                            Boolean(item._toggled) ? "- Collapse" : "+ Expand"
                          }}
                        </a>
                      </td>
                    </template>
                    <template #details="{ item, index }">
                      <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <table
                            class="table table-section table-striped border-bottom mb-0"
                            style="table-layout: fixed"
                          >
                            <tbody>
                              <tr
                                v-for="i in getSections(item.custom_display_id)"
                                :key="i.section_id"
                                style="background-color: white"
                              >
                                <td class="form-radio">
                                  <label>
                                    <input
                                      :name="item.custom_display_id"
                                      type="radio"
                                      :value="i.section_id"
                                      :checked="
                                        selected[item.custom_display_id] ==
                                        i.section_id
                                      "
                                      @input="
                                        handleRadio(
                                          item.custom_display_id,
                                          i.section_id
                                        )
                                      "
                                    />
                                  </label>
                                </td>
                                <td>
                                  <div
                                    class="row d-flex justify-content-between ml-0 mr-0"
                                  >
                                    <h6>
                                      {{ i.section_name }}
                                    </h6>
                                    <div
                                      v-if="
                                        selected[item.custom_display_id] ==
                                        i.section_id
                                      "
                                    >
                                      <em
                                        v-c-tooltip="'Reset'"
                                        class="fas fa-sync-alt mr-2 cursor-pointer"
                                        @click="resetSectionDescription(i)"
                                      >
                                      </em>
                                      <em
                                        v-c-tooltip="'Edit'"
                                        class="fas fa-edit cursor-pointer"
                                        @click="editSectionDescription(i)"
                                      >
                                      </em>
                                    </div>
                                  </div>
                                  <!-- job-section css class used to remove margin bottom space in <p> tag -->
                                  <p
                                    class="job-section"
                                    v-html="i.section_desc"
                                  ></p>
                                </td>
                              </tr>
                              <tr
                                v-if="
                                  !getSections(item.custom_display_id).length
                                "
                              >
                                <td class="text-center">
                                  No Section Template Found
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <CRow
                            v-if="getSections(item.custom_display_id).length"
                            class="d-flex justify-content-between"
                          >
                            <CButton
                              class="mt-2 btn btn-outline-primary small ml-3 rounded-0"
                              @click="
                                handleRadio(item.custom_display_id, false)
                              "
                            >
                              Clear Selection
                            </CButton>
                            <CButton
                              class="mt-2 btn btn-outline-primary small mr-3 rounded-0"
                              @click="toggleDetails(item, index)"
                            >
                              Select & Collapse
                            </CButton>
                          </CRow>
                        </CCardBody>
                      </CCollapse>
                    </template>
                  </CDataTable>
                </div>
                <div
                  v-if="!(getItems.length > 1) && !isFetchingJobData"
                  class="text-center mt-4 text-danger font-weight-thick"
                >
                  There are no job templates for this organization - Please
                  update the templates if required!
                </div>
                <div style="border: 1px solid lightgray; color: #4f5d73">
                  <CRow
                    class="justify-content-between ml-0 mr-0 hover-gray"
                    style="padding: 0.75rem"
                  >
                    Additional Information
                    <a
                      class="text-primary"
                      style="font-weight: 500; cursor: pointer"
                      @click="toggleAdditionalInfo"
                    >
                      {{ show_additional_info ? "- Collapse" : "+ Expand" }}
                    </a>
                  </CRow>
                  <transition name="fade">
                    <CRow class="p-3" v-show="show_additional_info">
                      <CCol md="12">
                        <CRow class="row mb-3">
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <rich-text-editor
                              name="Additional_Information"
                              :value="updatedSectionContents[0]"
                              @input="handleSectionAdditionalInfo"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </transition>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </form>
    </ValidationObserver>
    <div
      class="row-sm my-2 action-group d-flex justify-content-between flex-wrap"
    >
      <div class="btn-add-job-cancel">
        <button
          name="cancel"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
          @click="$parent.goToJobBoard()"
        >
          Cancel
        </button>
      </div>
      <div class="btn-add-job-submit">
        <button
          name="Back"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
          @click="onSave('back', 2)"
          :disabled="isLoading"
        >
          Back
          <CSpinner
            v-if="isLoading == 'back'"
            class="spinner-border-sm text-white m-1"
          />
        </button>

        <button
          name="draft"
          type="submit"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3 mr-2"
          @click="onSave('draft')"
          :disabled="isLoading"
        >
          Save as Draft
          <CSpinner
            v-if="isLoading == 'draft'"
            class="spinner-border-sm text-white m-1"
          />
        </button>

        <button
          name="next_page"
          type="submit"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3"
          @click="onSave('next', 4)"
          :disabled="isLoading"
        >
          {{ isEditJob ? "Save & Continue" : "Next" }}
          <CSpinner
            v-if="isLoading == 'next'"
            class="spinner-border-sm text-white m-1"
          />
        </button>
      </div>
    </div>
    <modal
      modalTitle="NOTIFICATION"
      modalColor="primary"
      modalContent=
      "    * Please choose all required templates from the sections below!
      * Then edit if necessary.   
      * Templates will revert back to their original text.
      Please request your Admin to create new standard templates if required!"
      :isShowPopup="hintModalPopup"
      :buttons="['Ok']"
      :modalCallBack="hintModalCallBack"
      :closeOnBackdrop="false"
      style="white-space: pre-wrap" 
      />
    <edit-job-section-templates
      v-if="editSectionModal.isShowPopup"
      :isShowPopup="editSectionModal.isShowPopup"
      :sectionDesc="editSectionModal.section_desc"
      :modalCallBack="editSectionModalCallBack"
    />
  </div>
</template>

<script>
import Modal from "../../components/reusable/Modal.vue";
import AddJob from "./AddJob";
import { deepClone, appendBaseUrlWithLink } from "@/helpers/helper";
import EditJobSectionTemplates from "./EditJobSectionTemplates";
import ImageSlider from "../../components/reusable/ImageSlider";

export default {
  mixins: [AddJob],
  components: {
    Modal,
    EditJobSectionTemplates,
    ImageSlider,
  },
  data: () => ({
    jobs: {},
    payload: {},
    fields: [
      {
        key: "display_name",
        label: "Select Sections",
        _style: "width:50%;",
      },
      { key: "expand", label: "" },
    ],
    details: [],
    collapseDuration: 0,
    selected: {},
    section_ids: [],
    isSectionChanged: false,
    hintModalPopup: false,
    expandAllSection: false,
    isLoading: false,
    isFetchingJobData: false,
    getItems: [],
    updatedSectionContents: {},
    editSectionModal: {
      isShowPopup: false,
      section_desc: "",
      sectionData: {},
    },
    selectedJobPicture: {},
    show_additional_info: false,
    renderTemplates: true,
    isEditJob: false,
    appendBaseUrlWithLink
  }),
  computed: {
    fetchJobData() {
      if (Object.keys(this.jobById).length) {
        const jobData = this.jobById;
        this.jobs = { ...jobData };
        this.getItems = this.sectionDisplayNames?.map((val, id) => {
          return {
            display_name: val.display_name,
            custom_display_id: val.display_name.split(" ").join("_"),
            id,
            _toggled: false,
          };
        });
      }
      return true;
    },
    sectionDisplayNames() {
      return this.jobById?.preferred_in_advert?.includes("salary")
        ? this.getSectionDisplayNames
        : this.getSectionDisplayNames?.filter(
            (val) => !val?.display_name.toLowerCase().includes("salary")
          );
    },
    getDefaultImageID() {
      if (this.jobs?.job_picture_id) return this.jobs?.job_picture_id;
      if (this.getJobPictures?.length) {
        this.selectedJobPicture = this.getJobPictures[0];
        return this.getJobPictures[0].id;
      }
      return null;
    },
    getImageUrl() {
      let url = "";
      if (this?.selectedJobPicture?.url) {
        url = this.selectedJobPicture.url;
        this.$emit("getImageUrl", url);
        return url;
      }
      if (this.jobs?.job_picture_id) {
        url = window.location.origin + this.jobs?.job_picture?.link_to_doc;
        this.$emit("getImageUrl", url);
        return url;
      }
      if (this.getJobPictures?.length) {
        url = this.getJobPictures[0].url;
        this.$emit("getImageUrl", url);
        return url;
      }
      return "";
    },
    getJobPictures() {
      let jobPictureDocumentID = 19;
      return (
        this.getAllOrganisationDocumentByType(jobPictureDocumentID)
          ?.map((val) => ({
            id: val.organisation_document_id,
            default_document: val.default_document,
            url: appendBaseUrlWithLink(val?.link_to_doc),
          }))
          .sort((a) => (a.id == this.jobs?.job_picture_id ? -1 : 1)) || []
      );
    },
  },
  methods: {
    handleRadio(name, val) {
      this.selected[name] = val;
      if (val === false) {
        delete this.selected[name];
      }
      this.isSectionChanged = true;
      this.section_ids = [];
      for (const [key, value] of Object.entries(this.selected)) {
        this.section_ids.push(value);
      }
      this.setTempSectionsContent();
    },
    handleSectionAdditionalInfo(name, val) {
      this.selected[name] = 0;
      if (!val) {
        delete this.selected[name];
      }
      this.updatedSectionContents[0] = val;
      this.section_ids = [];
      for (const [key, value] of Object.entries(this.selected)) {
        this.section_ids.push(value);
      }
      this.isSectionChanged = true;
      this.setTempSectionsContent();
    },
    // Set Preview Content
    setTempSectionsContent() {
      let tempSectionsContent = [];
      // removing component
      this.renderTemplates = false;
      this.$nextTick(() => {
        // Adding the component back in
        this.renderTemplates = true;
      });
      for (const [key, value] of Object.entries(this.selected)) {
        let content = this.getSections(key)?.filter((el) => {
          return value == el.section_id;
        })?.[0];
        let section_desc =
          this.updatedSectionContents[content?.section_id || 0];
        let preview = {
          section_id: content?.section_id || 0,
          section_desc: section_desc || "",
          section_template: content,
        };
        tempSectionsContent.push(preview);
      }
      this.$emit("tempSectionsContent", tempSectionsContent);
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggleAdditionalInfo() {
      this.show_additional_info = !this.show_additional_info;
      let isExpanded = false;
      this.getItems.forEach((val) => {
        if (val._toggled) isExpanded = true;
      });
      if (this.show_additional_info) isExpanded = true;
      if (isExpanded) this.expandAllSection = false;
    },
    toggleDetails(item) {
      this.getItems = JSON.parse(JSON.stringify(this.getItems));
      this.$set(this.getItems[item.id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
      // To check expand all func
      let isExpanded = false;
      this.getItems.forEach((val) => {
        if (val._toggled) isExpanded = true;
      });
      if (this.show_additional_info) isExpanded = true;
      if (isExpanded) this.expandAllSection = false;
    },
    // To expand all section
    expandAll(bool) {
      this.getItems?.forEach((item) => {
        this.$set(this.getItems[item.id], "_toggled", bool);
        this.collapseDuration = 300;
        this.$nextTick(() => {
          this.collapseDuration = 0;
        });
      });
      this.show_additional_info = bool;
      this.expandAllSection = bool;
    },
    // To get Section Descriptions
    getSections(display_name) {
      let data = this.getSectionByDisplayNames[display_name];
      data = deepClone(data);
      data = Object.keys(data).map((k) => data[k]);
      if (display_name.toLowerCase().includes("salary")) {
        data = data?.filter((val) => {
          if (val?.section_desc.search("%salary_dynamic_text%") != -1) {
            return this.setDynamicSalary(val);
          }
          return val;
        });
      }
      let updated_data = [];
      data?.forEach((val) => {
        if (this.updatedSectionContents[val.section_id]) {
          val.section_desc = this.updatedSectionContents[val.section_id];
        }
        updated_data.push(val);
      });
      return updated_data || [];
    },
    // To set Salary dynamic text
    setDynamicSalary(val) {
      let job = this.jobById;
      if (job?.salary_min && job?.salary_max) {
        val.section_desc = val.section_desc.replaceAll(
          "%salary_dynamic_text%",
          `${job?.salary_min_text} ${
            this.getCurrencyForSelectedID(job?.salary_currency_id)[0]?.label
          } to ${job?.salary_max_text} ${
            this.getCurrencyForSelectedID(job?.salary_currency_id)[0]?.label
          }`
        );
        return val;
      }
    },
    onStep(step) {
      this.$emit("step", step);
    },
    async onSave(selectedBtn, step) {
      const isValid = await this.$refs.JobTemplate.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.isLoading = selectedBtn;
      let appendAction = [];
      const { job_id, customer_uid, job_picture_id, organisation_uid } =
        this.jobById;
      if (this.isSectionChanged) {
        let createSections = {
          customer_uid: customer_uid,
          job_id: job_id,
          sections: [],
        };
        this.section_ids.forEach((val) => {
          let section_desc = this.updatedSectionContents[val];
          if (!val && !this.updatedSectionContents[val]) return;
          createSections.sections.push({
            job_id: job_id,
            section_id: val || null,
            section_desc: section_desc || "",
          });
        });
        appendAction = this.createJobDescSections(createSections);
      }
      if (
        this.selectedJobPicture?.id &&
        this.selectedJobPicture?.id != job_picture_id
      ) {
        let updatedPayload = {
          organisation: organisation_uid,
          job_id,
          customer_uid,
          job_picture_id: this.selectedJobPicture?.id,
          published: false,
        };
        appendAction = this.updateJobSteps(updatedPayload);
      }
      Promise.all([appendAction]).then(() => {
        if (appendAction?.length != 0) {
          this.fetchJobByID(job_id);
          if (selectedBtn == "draft" || selectedBtn == "back") {
            this.showToast({
              class: "bg-success text-white",
              message: "Updated successfully",
            });
          }
        }
        this.isLoading = false;
        this.payload = {};
        this.isSectionChanged = false;
        if (selectedBtn != "draft") this.onStep(step);
      });
    },
    showHint() {
      this.hintModalPopup = true;
    },
    hintModalCallBack() {
      this.hintModalPopup = false;
    },
    resetSectionDescription(section) {
      delete this.updatedSectionContents[section.section_id];
      this.setTempSectionsContent();
    },
    editSectionDescription(section) {
      this.editSectionModal.section_desc = section.section_desc;
      this.editSectionModal.sectionData = section;
      this.editSectionModal.isShowPopup = true;
    },
    editSectionModalCallBack(action, updatedSectionDesc) {
      if (action) {
        this.isSectionChanged = true;
        let section_id = this.editSectionModal.sectionData.section_id;
        this.updatedSectionContents[section_id] = updatedSectionDesc;
        this.setTempSectionsContent();
      }
      this.editSectionModal.isShowPopup = false;
    },
    selectedJobImage(item) {
      this.selectedJobPicture = item;
    },
  },
  mounted() {
    this.isFetchingJobData = true;
    const customer_uid = this.jobById?.customer_uid;
    const organisation_uid = this.jobById?.organisation_uid;
    const candidate_type_id = this.jobById?.candidate_type_id;
    this.section_ids = [];
    this.fetchSectionDisplayNames({ organisation_uid, customer_uid })
      .then((res) => {
        res.data?.forEach((val) => {
          this.fetchSectionByDisplayNames({
            display_name: val.display_name,
            organisation_uid,
            customer_uid,
            candidate_type_id: ["null", candidate_type_id],
          });
        });
        this.jobById?.job_desc_section_templates?.forEach((val) => {
          let display_name = val?.section_template?.display_name
            .split(" ")
            .join("_");
          // For Section Additional Information - section_id will be null (so here we hard coding as zero to avoid null)
          // And display name will be "Additional_Information"
          this.selected[display_name || "Additional_Information"] =
            val.section_id || 0;
          this.section_ids.push(val.section_id || 0);
          // Getting if any updated content in job desc section
          if (val?.section_desc) {
            this.updatedSectionContents[val.section_id || 0] = val.section_desc;
          }
        });
      })
      .finally(() => (this.isFetchingJobData = false));
    if (this.$router.currentRoute.name == "EditJob") this.isEditJob = true;
  },
};
</script>
