<template>
  <div class="action-advert-modal">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      :centered="true"
    >
      <template #header>
        <h6 class="modal-title">Preferred Fields</h6>
        <CButtonClose @click="setLastData()" class="text-black" />
      </template>
      <template #footer>
        <CButton class="btn px-4 btn-outline-primary" @click="setLastData()">
          Close
        </CButton>
        <CButton class="px-4 btn-primary" @click="onSubmit()">Save</CButton>
      </template>
      <div v-if="isFetching">
        <table
          class="table table-striped table-hover text-center border-bottom mb-0"
        >
          <thead>
            <tr style="background-color: lightgrey">
              <td style="width: 7%">
                <label>
                  <input type="checkbox" v-model="selectAll" @click="select" />
                </label>
              </td>
              <td style="font-weight: 600">
                Select the Additional Requirements
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="i in advertFields"
              :key="i.code"
              :style="`${
                selected.includes(i.code)
                  ? 'background-color: #D0F0C0'
                  : 'background-color: white'
              }`"
            >
              <td>
                <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="i.code"
                    v-model="selected"
                    @change="checkSelectAll"
                  />
                </label>
              </td>
              <td>
                {{ i.label }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModal>
  </div>
</template>
<script>
import AddJob from "./AddJob";
export default {
  name: "JobInfoAdvertModal",
  mixins: [AddJob],
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    jobData: {
      type: Object,
      default: {},
    },
  },
  computed: {
    isFetching() {
      if (Object.keys(this.jobData).length) {
        this.selected = this.jobData.preferred_in_advert;
      }
      return true;
    },
    advertFields() {
      if (this.isNonClinicAdmin && this.isUK) {
        return this.getFieldsInAdvert.filter(
          (val) =>
            val.code != "local_license_or_eligibility" &&
            val.code != "salary" &&
            val.code != "gender" &&
            val.code != "age" &&
            val.code != "nationality"
        );
      } else if (this.isUK) {
        return this.getFieldsInAdvert.filter(
          (val) =>
            val.code != "gender" &&
            val.code != "age" &&
            val.code != "nationality" &&
            val.code != "salary"
        );
      } else {
        return this.getFieldsInAdvert.filter((val) => val.code != "salary");
      }
    },
    isNonClinicAdmin() {
      return this.jobData.candidateType?.code == 6;
    },
    isUK() {
      return this.jobData.location_id?.country_id == 182;
    },
  },
  data: () => ({
    selected: [],
    selectAll: false,
  }),
  watch: {
    isShowPopup() {
      this.checkSelectAll();
    },
  },
  methods: {
    select() {
      for (let i in this.advertFields) {
        if (!this.selectAll) {
          if (!this.selected?.includes(this.advertFields[i].code))
            this.selected.push(this.advertFields[i].code);
        } else {
          this.selected = this.selected?.filter(
            (val) => val != this.advertFields[i].code
          );
        }
      }
    },
    setLastData() {
      this.$parent.isShowPopup = false;
      this.selected = this.jobData.preferred_in_advert;
    },
    onSubmit() {
      this.$parent.jobs.preferred_in_advert = this.selected;
      this.$parent.payload.preferred_in_advert = this.selected;
      this.$parent.isShowPopup = false;
    },
    checkSelectAll() {
      let temp = false;
      this.advertFields?.forEach((val) => {
        this.selected.includes(val.code) ? null : (temp = true);
      });
      temp ? (this.selectAll = false) : (this.selectAll = true);
    },
  },
  mounted() {
    if (!this.getFieldsInAdvert?.length) {
      this.fetchFieldsInAdvert();
    }
  },
};
</script>

<style lang="scss" scoped>
table tbody tr td {
  font-weight: 300;
}
</style>
